import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext } from "react";

import {
  NoDataTile,
  Spinner,
  Stack,
  StackItem,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { BhbProviderProfilePhotoPicker } from "@modules/settings/screens/users/components/user-online-booking-profile/BhbProviderProfilePhotoPicker.tsx";
import { getUserStylesSet } from "@modules/settings/screens/users/components/UserScreens.styles.tsx";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { StaticTagPickerField } from "@ui-components/form/StaticTagPickerField.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TagsSelectField } from "@ui-components/form/TagsSelectField.tsx";
import { TextEditorField } from "@ui-components/form/TextEditorField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";

import { UserOnlineBookingProfileFormValues } from "./UserOnlineBookingProfileForm.types.ts";
import { UserOnlineBookingProfileFormContext } from "./UserOnlineBookingProfileFormContext.ts";
import { UserOnlineBookingProfileFormHelper } from "./UserOnlineBookingProfileFormHelper.ts";
import { UserOnlineBookingProfileFormValidator } from "./UserOnlineBookingProfileFormValidator.tsx";
import { UserOnlineDisplayField } from "./UserOnlineDisplayField.tsx";

export const onlineBookingProfileNameOf =
  nameOfFactory<UserOnlineBookingProfileFormValues>();
const validator = new UserOnlineBookingProfileFormValidator();

interface UserOnlineBookingProfileFormProps {
  user: User;
  bhbProvider?: BhbProvider;
  userAvailability?: UserAvailabilityModel;
}

export const UserOnlineBookingProfileFormBase: React.FC = observer(() => {
  const { core, notification, bhb, practice } = useStores();

  const { user, bhbProvider, userAvailability, initialValues, onSubmit } =
    useContext(UserOnlineBookingProfileFormContext);

  const theme = useTheme();

  const { formFooter, formFields } = getUserStylesSet(theme);

  const fetch = async () => {
    const apptTypesForLocation = await bhb.getAppointmentTypesForLocation();
    const onlineApptTypes = apptTypesForLocation
      ? UserOnlineBookingProfileFormHelper.reduceToOnlineApptTypes(
          apptTypesForLocation
        )
      : [];
    return onlineApptTypes;
  };

  return (
    <DataFetcher fetch={fetch} fallback={<Spinner />}>
      {apptTypes => {
        return (
          <SubmissionForm<UserOnlineBookingProfileFormValues>
            formName="user-online-settings"
            autoFocus={false}
            styles={{ main: { borderRadius: 4 } }}
            validate={(values: UserOnlineBookingProfileFormValues) =>
              validator.validate(values)
            }
            readOnly={!core.hasPermissions(Permission.BhbWrite)}
            initialValues={initialValues}
            onSubmitSucceeded={() => {
              notification.success(`${user.fullName} has been updated.`);
            }}
            onSubmit={onSubmit}
            buttonsProps={{
              disableCancelOnPristine: true,
              disableSubmitOnFormInvalid: true,
              styles: {
                root: formFooter
              }
            }}
          >
            {() => {
              return (
                <Fieldset
                  styles={{
                    root: formFields
                  }}
                >
                  <Stack horizontal>
                    {bhbProvider && (
                      <BhbProviderProfilePhotoPicker
                        id={bhbProvider.id}
                        firstName={bhbProvider.firstName}
                        lastName={bhbProvider.lastName}
                      />
                    )}
                    <StackItem styles={{ root: { width: "100%" } }}>
                      <TextInputField
                        name={onlineBookingProfileNameOf("displayName")}
                        label="Display name"
                        placeholder="'Title'. 'Preferred name' 'Last name' if not specified"
                      />
                      <OptionsSelectField
                        name={onlineBookingProfileNameOf("language")}
                        label="Languages spoken"
                        placeholder="Select languages"
                        multiSelect
                        options={practice.ref.languages.keyTextValues}
                        showAllSelected
                        styles={{
                          fieldContent: { justifyContent: "flex-start" },
                          root: { flexGrow: 1, flexBasis: 0 }
                        }}
                      />
                      <StaticTagPickerField
                        name={onlineBookingProfileNameOf("areaOfInterest")}
                        label="Areas of interest"
                        fetchDataSource={() =>
                          bhb.ref.areasOfInterest.keyNameValues
                        }
                      />
                    </StackItem>
                  </Stack>
                  <TextEditorField
                    name={onlineBookingProfileNameOf("profileDetail")}
                    label="Biography"
                    placeholder="Introduce yourself to patients"
                  />
                  <TagsSelectField
                    label="Available appointment types"
                    name={onlineBookingProfileNameOf("appointmentTypes")}
                    options={apptTypes.map(({ key, text }) => ({
                      key,
                      name: text,
                      text
                    }))}
                  />
                  <UserOnlineDisplayField
                    user={user}
                    bhbProvider={bhbProvider}
                    userAvailability={userAvailability}
                  />
                </Fieldset>
              );
            }}
          </SubmissionForm>
        );
      }}
    </DataFetcher>
  );
});

export const UserOnlineBookingProfileForm: FunctionComponent<UserOnlineBookingProfileFormProps> =
  observer(({ user, bhbProvider, userAvailability }) => {
    if (!bhbProvider) {
      return (
        <NoDataTile
          textProps={{
            text: "Online booking profile not found. Please refresh."
          }}
          linkProps={{}}
        />
      );
    }

    const { core, bhb, userExperience } = useStores();

    const userOnlineBookingProfileFormHelper =
      new UserOnlineBookingProfileFormHelper({
        user,
        bhbProvider,
        userAvailability,
        core,
        bhb,
        userExperience
      });

    return (
      <UserOnlineBookingProfileFormContext.Provider
        value={userOnlineBookingProfileFormHelper}
      >
        <UserOnlineBookingProfileFormBase />
      </UserOnlineBookingProfileFormContext.Provider>
    );
  });
