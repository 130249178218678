import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import {
  bhbOnlineSettingsFormNameOf,
  BhbOnlineSettingsFormValues,
  buttonGroupOptons
} from "./BhbOnlineSettingsCard.types.ts";

export const BhbOnlineSettingsCancellationSection = observer(() => {
  const { bhb } = useStores();

  const {
    input: { value: allowCancellationsEnabled }
  } = useField<BhbOnlineSettingsFormValues["allowCancellationsEnabled"]>(
    bhbOnlineSettingsFormNameOf("allowCancellationsEnabled")
  );

  const appointmentTypeOptions = Array.from(bhb.appointmentTypesMap.values())
    .filter(
      type => type.isAvailableExistingPatients || type.isAvailableNewPatients
    )
    .map(t => ({
      key: t.id,
      text: t.name
    }));

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
      >
        <Heading variant="section-heading-light" labelPaddings>
          Appointment cancellation / rescheduling
        </Heading>
        <ToggleField
          name={bhbOnlineSettingsFormNameOf("allowCancellationsEnabled")}
          onText="Allowed"
          offText="Disabled"
          styles={{ root: { marginBottom: 0 } }}
        />
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Heading labelPaddings>No later than</Heading>
        <SpinNumberInputField
          name={bhbOnlineSettingsFormNameOf("minimumTimeToCancelValue")}
          parse={value => Number(value)}
          min={1}
          disabled={!allowCancellationsEnabled}
          styles={{ root: { width: 80 } }}
        />
        <ButtonsGroupSingleChoiceField
          options={buttonGroupOptons}
          name={bhbOnlineSettingsFormNameOf("minimumTimeToCancelUnit")}
          disabled={!allowCancellationsEnabled}
        />
        <Heading labelPaddings>before the appointment</Heading>
      </Stack>
      <OptionsSelectField
        options={appointmentTypeOptions}
        name={bhbOnlineSettingsFormNameOf("nonCancellableAppointmentTypes")}
        label="Appointment types NOT allowed for cancellation online (via call to clinic only)"
        multiSelect
        disabled={!allowCancellationsEnabled}
        hideSearchOption
      />
    </>
  );
});
