import { FunctionComponent, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IButtonProps,
  IDialogContentProps
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StatementGenerateModalFields } from "@modules/billing/screens/statement/components/StatementGenerateModalFields.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { StatementItemsContext } from "../context/StatementItemsContext.ts";
import { StatementGenerateFormValues } from "./StatementGenerateModal.type.ts";

export const StatementGenerateModal: FunctionComponent = () => {
  const { setIsStatementNewModalVisible, handleSubmitAction } = useContext(
    StatementItemsContext
  );

  const submitButtonProps: IButtonProps = {
    ...dataAttribute(DataAttributes.Element, "submit-button"),
    text: "Generate",
    iconProps: { iconName: undefined }
  };

  const initialValues: StatementGenerateFormValues = {
    startDate: DateTime.today()
      .minus({ months: 1 })
      .startOf("month")
      .toJSDate(),
    endDate: DateTime.today().minus({ months: 1 }).endOf("month").toJSDate(),
    owingOver: 0,
    creditOver: 0,
    valueFilter: false
  };

  const dialogContentProps: IDialogContentProps = {
    title: <Heading variant="modal-heading">New statement run</Heading>,
    showCloseButton: true
  };

  const onSubmit = async (values: StatementGenerateFormValues) => {
    await handleSubmitAction(values);
  };

  return (
    <SubmissionFormDialog<StatementGenerateFormValues>
      dialogName="statmentRun"
      initialValues={initialValues}
      onSubmitSucceeded={() => setIsStatementNewModalVisible(false)}
      onSubmit={onSubmit}
      dialogProps={{
        showTitleSeparator: false,
        onDismiss() {
          setIsStatementNewModalVisible(false);
        },
        minWidth: 600,
        dialogContentProps
      }}
      buttonsProps={{ submitButtonProps }}
    >
      {() => <StatementGenerateModalFields />}
    </SubmissionFormDialog>
  );
};
