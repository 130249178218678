import { DataTimeFormats, Link } from "@bps/fluent-ui";
import { Statement } from "@stores/billing/models/Statement.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface StatementLinkCellProps {
  statement: Statement;
}

export const StatementLinkCell: React.FC<StatementLinkCellProps> = ({
  statement
}) => {
  const { billing } = useStores();

  return (
    <Link
      onClick={() => {
        if (statement?.id) {
          billing.openStatementPdf(statement.id);
          billing.ui.setStatementDocumentPreviewData({
            contactId: statement.accountContactId,
            statementId: statement.id,
            statement
          });
        }
      }}
    >
      {`Statement ${statement.startDate.toFormat(
        DataTimeFormats.DAY_DEFAULT_FORMAT
      )} - ${statement.endDate.toFormat(DataTimeFormats.DAY_DEFAULT_FORMAT)}`}
    </Link>
  );
};
