import { FormApi } from "final-form";

import {
  PatchBhbLocationDto,
  ShowAvailabilityEnum
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { BhbAppointmentType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbLocation } from "@stores/bhb/models/BhbLocation.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import {
  BhbOnlineSettingsFormValues,
  DurationUnit,
  DurationValue
} from "./BhbOnlineSettingsCard.types.ts";

export class BhbOnlineSettingsFormModel {
  constructor(
    private root: RootStore,
    private location: BhbLocation,
    private appointmentTypes: BhbAppointmentType[]
  ) {}

  // initial values

  getDurationValue = (valueInMinutes?: number): DurationValue => {
    if (!valueInMinutes) {
      return { unitValue: 0, unit: "minutes" };
    }

    const valueInHours = valueInMinutes / 60;
    if (valueInHours % 1 > 0) {
      return { unitValue: valueInMinutes, unit: "minutes" };
    }

    const valueInDays = valueInHours / 24;

    return valueInDays % 1 === 0
      ? { unitValue: valueInDays, unit: "days" }
      : { unitValue: valueInHours, unit: "hours" };
  };

  convertMinimumTimeToBookToValues = (minimumTimeToBook: number) => {
    let minimumTimeToBookUnit: DurationUnit = "minutes";
    let minimumTimeToBookValue = 1;
    const minutesInDay = 60 * 24;
    const minutesInHour = 60 * 1;

    if (minimumTimeToBook) {
      if (
        minimumTimeToBook / minutesInDay ===
        Math.floor(minimumTimeToBook / minutesInDay)
      ) {
        minimumTimeToBookUnit = "days";
        minimumTimeToBookValue = minimumTimeToBook / minutesInDay;
      } else if (
        minimumTimeToBook / minutesInHour ===
        Math.floor(minimumTimeToBook / minutesInHour)
      ) {
        minimumTimeToBookUnit = "hours";
        minimumTimeToBookValue = minimumTimeToBook / minutesInHour;
      } else {
        minimumTimeToBookUnit = "minutes";
        minimumTimeToBookValue = minimumTimeToBook;
      }
    }

    return { minimumTimeToBookUnit, minimumTimeToBookValue };
  };

  getInitialValues = () => {
    const {
      unitValue: minimumTimeToCancelValue,
      unit: minimumTimeToCancelUnit
    } = this.getDurationValue(this.location.minimumTimeToCancel);

    const { minimumTimeToBookUnit, minimumTimeToBookValue } =
      this.convertMinimumTimeToBookToValues(this.location.minimumTimeToBook);

    const nonCancellableAppointmentTypes = this.appointmentTypes.filter(
      t => !t.isCancellable
    );

    const initialValues: BhbOnlineSettingsFormValues = {
      hasLogoUrl: !!this.location.logoUrl,
      logoUrl: this.location.logoUrl,
      allowCancellationsEnabled: Boolean(
        this.location.allowCancellationsEnabled
      ),
      minimumTimeToCancelValue,
      minimumTimeToCancelUnit,
      nonCancellableAppointmentTypes: nonCancellableAppointmentTypes
        .filter(
          type =>
            type.isAvailableExistingPatients || type.isAvailableNewPatients
        )
        .map(t => t.id),

      showAvailability: String(
        this.location.showAvailability ?? ShowAvailabilityEnum.oneWeek
      ),
      minimumTimeToBookValue,
      minimumTimeToBookUnit,
      limitMaximumAppointmentsPerDay: !!this.location.maximumAppointmentsPerDay,
      maximumAppointmentsPerDay: this.location.maximumAppointmentsPerDay ?? 1,
      policy: this.location.policy ?? "",
      url: this.location.url ?? "",
      emergencyMessage: this.location.emergencyMessage ?? "",
      emergencyMessageEnabled: Boolean(this.location.emergencyMessageEnabled)
    };

    return initialValues;
  };

  // on submit

  getValueInMinutes = (unitValue: number, unit: DurationUnit) => {
    switch (unit) {
      case "days":
        return unitValue * 24 * 60;
      case "hours":
        return unitValue * 60;
      case "minutes":
      default:
        return unitValue;
    }
  };

  onSubmit = async (
    values: BhbOnlineSettingsFormValues,
    form: FormApi<BhbOnlineSettingsFormValues>
  ) => {
    const locations = await this.root.practice.getAllOrgUnitsLocationData();

    const bhbLocations = await Promise.all(
      locations.map(({ id }) => this.root.bhb.getLocation(id))
    );

    const { initialValues } = form.getState();

    const minimumTimeToCancel = this.getValueInMinutes(
      values.minimumTimeToCancelValue,
      values.minimumTimeToCancelUnit
    );

    const minimumTimeToBook = this.getValueInMinutes(
      values.minimumTimeToBookValue,
      values.minimumTimeToBookUnit
    );

    const patchValues: Omit<PatchBhbLocationDto, "id" | "eTag"> = {
      logoUrl: values.logoUrl,
      allowCancellationsEnabled: values.allowCancellationsEnabled,
      minimumTimeToCancel,
      showAvailability: Number(values.showAvailability),
      minimumTimeToBook,
      maximumAppointmentsPerDay: values.limitMaximumAppointmentsPerDay
        ? values.maximumAppointmentsPerDay
        : undefined,
      policy: values.policy,
      url: values.url,
      emergencyMessage:
        values.emergencyMessage?.trim() !== "<p><br></p>" // Empty state of react-quill
          ? values.emergencyMessage
          : undefined,
      emergencyMessageEnabled: values.emergencyMessageEnabled
    };

    const appointmentTypes = Array.from(
      this.root.bhb.appointmentTypesMap.values()
    );

    const appointmentTypesToUpdate = appointmentTypes
      .filter(
        appointmenType =>
          (values.nonCancellableAppointmentTypes.includes(appointmenType.id) &&
            appointmenType.isCancellable) || // Has been selected
          (!values.nonCancellableAppointmentTypes.includes(appointmenType.id) && // Has been unselected
            initialValues.nonCancellableAppointmentTypes?.includes(
              appointmenType.id
            ))
      )
      .map(appointmenType => {
        return {
          ...appointmenType.dto,
          isCancellable: !appointmenType.isCancellable
        };
      });

    await Promise.all([
      ...bhbLocations.map(bhbLocation =>
        this.root.bhb.patchLocation({
          id: bhbLocation.id,
          eTag: bhbLocation.eTag,
          ...patchValues
        })
      ),
      ...appointmentTypesToUpdate.map(
        this.root.bhb.putAppointmentTypeForLocation
      )
    ]);

    this.root.notification.success("Online booking settings updated");
  };
}
