import { PatchBhbLocationDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { BhbLocation } from "@stores/bhb/models/BhbLocation.ts";
import { RootStore } from "@stores/root/RootStore.ts";

import { BhbConfigurationFormValues } from "./BhbConfigurationSettingsForm.types.ts";

export class BhbConfigurationSettingsFormModel {
  constructor(
    private root: RootStore,
    private location: BhbLocation
  ) {}

  getInitialValues = () => {
    return {
      displayName: this.location.name ?? "",
      onlineBookingsEnabled: !!this.location.onlineBookingsEnabled,
      parkingAccess: this.location.parkingAccess ?? "",
      disabilityAccess: this.location.disabilityAccess ?? ""
    };
  };

  onSubmit = async (values: BhbConfigurationFormValues) => {
    const patchValues: PatchBhbLocationDto = {
      id: this.location.id,
      eTag: this.location.eTag,
      name: values.displayName,
      onlineBookingsEnabled: values.onlineBookingsEnabled,
      parkingAccess: values.parkingAccess,
      disabilityAccess: values.disabilityAccess
    };

    await this.root.bhb.patchLocation(patchValues);

    this.root.notification.success("BHB configuration updated");
  };
}
