import { FunctionComponent } from "react";

import {
  BadgeSize,
  DirectionalHint,
  FontIcon,
  FontWeights,
  Grid,
  HoverCard,
  HoverCardType,
  ICalloutProps,
  ITextStyles,
  mergeStyles,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  AgentClinicalDataItemDto,
  DescriptionTag,
  NatureOfReaction,
  ReactionType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ReactionAgentKind } from "@libs/gateways/drugs/DrugsGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import {
  NatureOfReactionText,
  ReactionSeverityText
} from "@ui-components/RefText.tsx";

import { RecordUpdateCheckedLog } from "../../../shared-components/RecordUpdateCheckedLog.tsx";
import { getSeverityStylingOptions, ReactionBadge } from "./ReactionBadge.tsx";
import { AgentReaction } from "./ReactionForm.types.ts";

export interface ReactionTooltipProps {
  reaction: AgentReaction;
  natureOfReaction: NatureOfReaction | string;
  calloutProps?: ICalloutProps;
  agent?: AgentClinicalDataItemDto;
}

export const ReactionTooltipBase: FunctionComponent<ReactionTooltipProps> = ({
  calloutProps,
  reaction,
  agent,
  natureOfReaction
}) => {
  const theme = useTheme();
  const { clinical } = useStores();
  const certainties = clinical.ref.reactionCertainties.keyTextValues;
  const isOther = reaction?.agent?.originalText === ReactionAgentKind.Other;

  const naturesOfReactionLength = isOther
    ? reaction.naturesOfReaction.filter(x => x.otherText === reaction.otherText)
        .length
    : reaction.naturesOfReaction.length;

  const renderAgentText = (styles?: ITextStyles) => {
    const { type, name } = reaction;
    let descriptionTag = null;
    let otherText = null;

    if (name === ReactionAgentKind.Other) {
      if (isOther) {
        otherText = reaction?.otherText;
      }
    }

    switch (type) {
      case ReactionType.DrugClass:
        descriptionTag = <strong>{DescriptionTag.Class}</strong>;
        break;
      case ReactionType.NonDrug:
        descriptionTag = <strong>{DescriptionTag.NonDrug}</strong>;
        break;

      default:
        descriptionTag = null;
    }

    return (
      <Text styles={styles}>
        {otherText ? otherText : name}
        {name !== ReactionAgentKind.Other && descriptionTag}
      </Text>
    );
  };

  const reactionIconColor = getSeverityStylingOptions(theme, reaction.severity)
    ?.iconColor;

  const reactionIconName = getSeverityStylingOptions(theme, reaction.severity)
    ?.iconName;

  const options = clinical.ref.natureOfReactions.keyTextValues;
  const isFreeText = options.every(({ key }) => key !== natureOfReaction);

  return (
    <HoverCard
      type={HoverCardType.plain}
      plainCardProps={{
        directionalHint: DirectionalHint.rightCenter,
        directionalHintFixed: true,
        calloutProps: {
          ...calloutProps,
          isBeakVisible: true,
          gapSpace: 5,
          calloutWidth: 348
        },
        onRenderPlainCard: () => (
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{
              root: {
                padding: 16
              }
            }}
          >
            <div
              className={mergeStyles({
                display: "grid",
                gridTemplateColumns: "max-content auto",
                gridGap: "12px 32px",
                alignItems: "baseline"
              })}
            >
              <Stack
                horizontal
                tokens={{ childrenGap: 8 }}
                horizontalAlign="center"
                verticalAlign="center"
              >
                <div
                  className={mergeStyles({
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  })}
                >
                  <ReactionBadge
                    severity={reaction.severity}
                    size={BadgeSize.Small}
                    styles={{
                      root: {
                        display: "flex",
                        borderRadius: 4,
                        flex: 1
                      },
                      content: {
                        flex: 1,
                        padding: "4px 16px 4px 16px"
                      }
                    }}
                  >
                    <FontIcon
                      iconName={reactionIconName}
                      styles={{
                        root: {
                          color: reactionIconColor
                        }
                      }}
                    />
                    <ReactionSeverityText
                      code={reaction.severity}
                      styles={{
                        root: {
                          fontWeight: FontWeights.semibold,
                          color: reactionIconColor
                        }
                      }}
                    />
                  </ReactionBadge>
                </div>
                <span>
                  <NatureOfReactionText
                    code={reaction.natureOfReaction}
                    styles={{
                      root: {
                        fontWeight: FontWeights.semibold,
                        color: reactionIconColor
                      }
                    }}
                  />
                </span>
              </Stack>
            </div>
            {renderAgentText({ root: { fontSize: 18 } })}
            <Text
              variant="small"
              styles={{ root: { color: theme.palette.neutralPrimary } }}
            >
              Certainty:{" "}
              {certainties.find(x => x.key === reaction.certainty)?.text}
            </Text>
            {agent?.comment && (
              <Stack
                styles={{
                  root: {
                    textOverflow: "initial",
                    wordBreak: "break-all",
                    fontSize: 12
                  }
                }}
              >
                Comment: {agent.comment}
              </Stack>
            )}

            <RecordUpdateCheckedLog
              fontSize="small"
              createdBy={reaction.createLog?.createdById}
              createdDate={reaction.createLog?.createdDateTime}
              updatedBy={reaction.updateLog?.updatedById}
              updatedDate={reaction.updateLog?.updatedDateTime}
            />
          </Stack>
        )
      }}
    >
      <Grid
        templateColumns="18px auto auto"
        verticalAlign="start"
        childrenTokens={{ gap: 8 }}
        styles={{
          root: {
            padding: 2
          }
        }}
      >
        <FontIcon
          iconName={reactionIconName}
          styles={{
            root: {
              color: reactionIconColor,
              padding: "0px 4px",
              lineHeight: "19px"
            }
          }}
        />
        <Stack
          horizontal
          tokens={{
            childrenGap: 4
          }}
        >
          {naturesOfReactionLength > 1 && (
            <Text>({naturesOfReactionLength})</Text>
          )}
          {renderAgentText()}
        </Stack>
        <span
          className={mergeStyles({
            flexGrow: 1,
            fontWeight: FontWeights.semibold,
            color: reactionIconColor,
            textAlign: "right",
            fontSize: 12,
            lineHeight: "19px"
          })}
        >
          {isFreeText ? (
            <Text
              bold
              variant="small"
              styles={{ root: { color: reactionIconColor } }}
            >
              {natureOfReaction}
            </Text>
          ) : (
            <NatureOfReactionText code={reaction.natureOfReaction} />
          )}
        </span>
      </Grid>
      <span style={{ display: "none" }} />
    </HoverCard>
  );
};

export const ReactionTooltip = withFetch(
  x => [
    x.clinical.ref.reactionCertainties.load(),
    x.clinical.ref.natureOfReactions.load()
  ],
  ReactionTooltipBase
);
