import {
  greaterThan,
  integer,
  lessThan,
  maxLength,
  predicate,
  required,
  url
} from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  bhbOnlineSettingsFormNameOf,
  BhbOnlineSettingsFormValues
} from "./BhbOnlineSettingsCard.types.ts";

export class BhbOnlineSettingsFormValidator extends Validator<BhbOnlineSettingsFormValues> {
  constructor() {
    super();
    this.forField(bhbOnlineSettingsFormNameOf("url"), [
      predicate((val: string) => val?.length > 0, url())
    ]);
    this.forField(bhbOnlineSettingsFormNameOf("policy"), [maxLength(8000)]);
    this.forField(bhbOnlineSettingsFormNameOf("minimumTimeToCancelValue"), [
      predicate(x => x !== 0, required()),
      integer(),
      greaterThan(0),
      lessThan(90)
    ]);
    this.forField(bhbOnlineSettingsFormNameOf("minimumTimeToBookValue"), [
      predicate(x => x !== 0, required()),
      integer(),
      greaterThan(0)
    ]);
  }
}
