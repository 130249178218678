import { observer } from "mobx-react-lite";

import {
  CopyToClipboardButton,
  DirectionalHint,
  Heading,
  Link,
  Stack
} from "@bps/fluent-ui";

interface BhbBookingUrlProps {
  locationId: string;
}

export const BhbBookingUrl: React.FC<BhbBookingUrlProps> = observer(
  ({ locationId }) => {
    const bookingUrl = `${window.appConfig.bhbBookingUrl}?locationId=${locationId}`;

    return (
      <Stack>
        <Heading labelPaddings>Unique booking URL</Heading>
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Link
            href={bookingUrl}
            target="_blank"
            styles={{
              root: { overflow: "hidden", textWrap: "nowrap" }
            }}
          >
            {bookingUrl}
          </Link>
          <CopyToClipboardButton
            directionalHint={DirectionalHint.topRightEdge}
            clipboardContent={bookingUrl}
            text="Copy link"
            buttonProps={{
              styles: { textContainer: { textWrap: "nowrap" } }
            }}
          />
        </Stack>
      </Stack>
    );
  }
);
