import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export interface BhbConfigurationFormValues {
  displayName: string;
  onlineBookingsEnabled: boolean;
  parkingAccess: string;
  disabilityAccess: string;
}

export const bhbConfigurationFormNameOf =
  nameOfFactory<BhbConfigurationFormValues>();
