import { computed } from "mobx";

import { BhbAppointmentTypeDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { BhbStore } from "@stores/bhb/BhbStore.ts";
import {
  BhbAppointmentType,
  BhbOnlineApptType
} from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { User } from "@stores/core/models/User.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { UserOnlineBookingProfileFormValues } from "./UserOnlineBookingProfileForm.types.ts";

export interface UserOnlineBookingProfileFormHelperProps {
  user: User;
  bhbProvider?: BhbProvider;
  userAvailability?: UserAvailabilityModel;
  userExperience: UserExperienceStore;
  core: CoreStore;
  bhb: BhbStore;
}

export class UserOnlineBookingProfileFormHelper {
  constructor(private props: UserOnlineBookingProfileFormHelperProps) {}

  private get core(): CoreStore {
    return this.props.core;
  }

  private get bhb(): BhbStore {
    return this.props.bhb;
  }

  private get userExperience(): UserExperienceStore {
    return this.props.userExperience;
  }

  @computed
  get user(): User {
    return this.props.user;
  }

  @computed
  get bhbProvider(): BhbProvider | undefined {
    return this.props.bhbProvider;
  }

  @computed
  get userAvailability(): UserAvailabilityModel | undefined {
    return this.props.userAvailability;
  }

  get initialValues(): UserOnlineBookingProfileFormValues {
    const appointmentTypes = this.bhbProvider?.appointmentTypes.map(x => x.id);

    return {
      displayName: this.bhbProvider?.displayName ?? this.user.fullName,
      language: this.bhbProvider?.language,
      areaOfInterest: this.bhbProvider?.areaOfInterest,
      profileDetail: this.bhbProvider?.profileDetail,
      providerOnlineStatusCode: this.bhbProvider?.providerOnlineStatus,
      appointmentTypes,
      photoUrl: this.bhbProvider?.photoUrl
    };
  }

  saveUserSetting = async (userSetting: UserSetting) => {
    await this.userExperience.upsertUserSetting({
      showOnCalendar: !userSetting.showOnCalendar,
      userId: userSetting.userId
    });
  };

  static reduceToOnlineApptTypes = (apptTypes: BhbAppointmentType[]) => {
    const onlineAppointmentTypes = (Array.from(apptTypes?.values()) ?? []).sort(
      (a, b) => a.name.localeCompare(b.name)
    );

    const apptTypesReduced = onlineAppointmentTypes.reduce(
      (filtered: BhbOnlineApptType[], type) => {
        if (type.isAvailableExistingPatients || type.isAvailableNewPatients) {
          const bhbOnlineApptType = {
            key: type.id,
            data: type,
            text: type.name
          };
          return [...filtered, bhbOnlineApptType];
        }
        return filtered;
      },
      []
    );
    return apptTypesReduced;
  };

  onSubmit = async (values: UserOnlineBookingProfileFormValues) => {
    const apptTypes = values.appointmentTypes;

    const apptTypesForLocation =
      await this.bhb.getAppointmentTypesForLocation();

    const onlineApptTypes = apptTypesForLocation
      ? UserOnlineBookingProfileFormHelper.reduceToOnlineApptTypes(
          apptTypesForLocation
        )
      : [];

    let updatedApptTypes: BhbAppointmentTypeDto[] = [];

    if (apptTypes) {
      updatedApptTypes = Array.from(onlineApptTypes.values())
        .flatMap(a => (a.data !== undefined ? a.data.dto : []))
        .filter(a => apptTypes.includes(a.id));
    }

    if (
      this.bhbProvider &&
      this.core.hasPermissions([
        Permission.BhbConfigAllowed,
        Permission.BhbWrite
      ])
    ) {
      const updatedProvider = {
        ...this.bhbProvider.dto,
        displayName: values.displayName,
        language: values?.language,
        providerOnlineStatus: values.providerOnlineStatusCode,
        areaOfInterest: values?.areaOfInterest,
        profileDetail: values?.profileDetail,
        appointmentTypes: updatedApptTypes,
        photoUrl: values.photoUrl
      };

      await this.bhb.updateProviderForLocation(
        updatedProvider,
        this.core.locationId // temporary
      );
    }
  };
}
