import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

import {
  Callout,
  dataAttribute,
  DataAttributes,
  DirectionalHint,
  ICalloutProps,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import { CalendarEventPriority } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { BookingEventCalloutDataFetcher } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/popup/BookingEventCalloutDataFetcher.tsx";
import { BookingEventCalloutNextStatusInput } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/popup/BookingEventCalloutNextStatusInput.tsx";
import { BookingEventCalloutPatientNotices } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/popup/BookingEventCalloutPatientNotices.tsx";
import { BookingEventCalloutStatusDropdown } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/popup/BookingEventCalloutStatusDropdown.tsx";
import { NextAppointmentInfoLink } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/popup/NextAppointmentInfoLink.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { NoChargeCommentDialog } from "@ui-components/NoChargeCommentDialog.tsx";

import { BookingCalendarEventContext } from "../BookingCalendarEventContext.tsx";
import { AppointmentNotices } from "./AppointmentNotices.tsx";
import { AttendeeRows } from "./AttendeeRows.tsx";
import { BookingEventCalloutCommandBar } from "./BookingEventCalloutCommandBar.tsx";
import { BookingEventCalloutHeader } from "./BookingEventCalloutHeader.tsx";
import { BookingEventDetails } from "./BookingEventDetails.tsx";
import { ConditionRow } from "./ConditionRow.tsx";
import { CopyThisAppointmentButton } from "./CopyThisAppointmentButton.tsx";
import { PersonaRow } from "./PersonaRow.tsx";

interface BookingEventCalloutProps extends Omit<ICalloutProps, "children"> {}

export const BookingEventCallout: React.FC<BookingEventCalloutProps> = observer(
  props => {
    const {
      calendarEvent,
      isSeries,
      isUnavailableType,
      isPatientCalendarEvent,
      preventDismiss,
      setNoChargeDialogVisibility,
      isNoChargeDialogVisible,
      updateNoChargeComment,
      invoiceItems
    } = useContext(BookingCalendarEventContext);

    const { typeRef, isGroupAppointment } = calendarEvent;

    const { onDismiss } = props;

    const eventTypeText =
      isSeries && isUnavailableType
        ? "Break"
        : (typeRef && typeRef.text) || "Appointment";

    const urgent = calendarEvent.priority === CalendarEventPriority.Urgent;

    const { billing } = useStores();
    return (
      <BookingEventCalloutDataFetcher>
        {() => (
          <Callout
            {...dataAttribute(DataAttributes.Element, "booking-event-callout")}
            setInitialFocus
            role="dialog"
            gapSpace={0}
            calloutWidth={575} // temporarily increased from 545 - PBI 44700
            directionalHint={DirectionalHint.rightCenter}
            preventDismissOnEvent={() => preventDismiss}
            {...props}
          >
            <BookingEventCalloutHeader
              onDismiss={onDismiss}
              eventTypeText={eventTypeText}
            />
            <BookingEventCalloutCommandBar />
            {urgent && (
              <MessageBar messageBarType={MessageBarType.severeWarning}>
                This is an urgent {eventTypeText}
              </MessageBar>
            )}
            <PersonaRow />
            {isNoChargeDialogVisible && (
              <NoChargeCommentDialog
                setDialogVisibility={setNoChargeDialogVisibility}
                noChargeComment={calendarEvent.noChargeComment || ""}
                onSave={async (noChargeComment: string) => {
                  const patientId = calendarEvent?.contact?.id;
                  if (!patientId) return;
                  await updateNoChargeComment(noChargeComment, patientId);

                  if (invoiceItems && invoiceItems.length > 0) {
                    const invoicIds = invoiceItems.map(x => x.id);
                    await billing.deleteBulkDraftItems(invoicIds);
                  }
                }}
              />
            )}
            <Stack
              styles={{ root: { padding: "16px 24px" } }}
              tokens={{ childrenGap: 16 }}
            >
              <BookingEventDetails
                calendarEvent={calendarEvent}
                eventTypeText={eventTypeText}
              />

              <NextAppointmentInfoLink calendarEvent={calendarEvent} />

              <AttendeeRows />

              {!isGroupAppointment && (
                <>
                  {isPatientCalendarEvent && (
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                      <BookingEventCalloutStatusDropdown
                        onDismiss={props.onDismiss}
                      />
                      <BookingEventCalloutNextStatusInput
                        onDismiss={props.onDismiss}
                      />
                      <CopyThisAppointmentButton />
                    </Stack>
                  )}

                  <AppointmentNotices />

                  <ConditionRow />

                  <BookingEventCalloutPatientNotices />
                </>
              )}
            </Stack>
          </Callout>
        )}
      </BookingEventCalloutDataFetcher>
    );
  }
);
