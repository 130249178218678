import { ButtonsGroupOption } from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

export type DurationUnit = "minutes" | "hours" | "days";

export type DurationValue = {
  unitValue: number;
  unit: DurationUnit;
};

export interface BhbOnlineSettingsFormValues {
  hasLogoUrl: boolean;
  logoUrl: string;
  url: string;
  policy: string;
  emergencyMessageEnabled: boolean;
  emergencyMessage: string;
  // Appointment cancellation / rescheduling
  allowCancellationsEnabled: boolean;
  minimumTimeToCancelValue: number;
  minimumTimeToCancelUnit: DurationUnit;
  nonCancellableAppointmentTypes: string[];
  // Booking availability timeframes & limits
  showAvailability: string;
  minimumTimeToBookValue: number;
  minimumTimeToBookUnit: DurationUnit;
  limitMaximumAppointmentsPerDay: boolean;
  maximumAppointmentsPerDay: number;
}

export const bhbOnlineSettingsFormNameOf =
  nameOfFactory<BhbOnlineSettingsFormValues>();

export const buttonGroupOptons: ButtonsGroupOption<DurationUnit>[] = [
  { key: "minutes", text: "Mins" },
  { key: "hours", text: "Hours" },
  { key: "days", text: "Days" }
];
