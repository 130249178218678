import { DateTime } from "@bps/utils";
import { CalendarEventAttendeeDto } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";

export interface AppointmentFormProps {
  onCancel: () => void;
}

export type GroupApptSlots = {
  providerId: string;
  appointmentTimes: GroupApptSlot[];
};

export type GroupApptSlot = {
  startTime: string;
  startDateTime: DateTime;
  remainingSpaces: number;
  calendarEventId: string;
  attendees: CalendarEventAttendeeDto[];
  orgUnitId: string;
};
export const appointmentFormNameOf = nameOfFactory<AppointmentFormValues>();
