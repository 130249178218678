import { getPatientEventsData } from "app-shell/NavBar/PatientAppointmentSearch/utils.ts";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useContext } from "react";

import {
  NoDataTile,
  ScrollablePane,
  SelectionMode,
  Stack,
  Tile,
  useFormContext
} from "@bps/fluent-ui";
import { CalendarEventStatus } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { RunQueryOptions } from "@libs/utils/promise-observable/promise-observable.types.ts";
import { ClinicalActivityManagementFilterValues } from "@modules/clinical/screens/patient-record/components/clinical-activity/ClinicalActivityManagementFilterBase.tsx";
import { ClinicalActivityType } from "@modules/clinical/screens/patient-record/components/clinical-activity/types/clinical-activity.type.ts";
import { ClinicalReminderFilter } from "@modules/clinical/screens/patient-record/components/clinical-reminder/ClinicalReminderFilter.tsx";
import { ClinicalRemindersTable } from "@modules/clinical/screens/patient-record/components/clinical-reminder/ClinicalRemindersTable.tsx";
import { ClinicalActivityTableRow } from "@modules/clinical/screens/patient-record/components/clinical-reminder/types/clinical-activity-table.type.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";

export interface RemindersClinicalProps {
  setSelected?: (selected: ClinicalActivity[]) => void;
  showAsFollowUp?: boolean;
}
const RemindersClinicalListBase: FC<RemindersClinicalProps> = observer(
  props => {
    const {
      state: { values: filter }
    } = useFormContext<ClinicalActivityManagementFilterValues>();

    const { setSelectedClinicalActivities } = useContext(InboxScreenContext);

    const { clinical, practice, booking } = useStores();
    const { setSelected } = props;
    const getItems = useCallback(
      async (options?: RunQueryOptions) => {
        const clinicalReminders = await clinical.getClinicalActivities({
          ...options,
          ...filter,
          patientName: filter.patientSearch,
          clinicalActivityTypes: [ClinicalActivityType.Reminder]
        });

        const patientIds = Array.from(
          new Set(clinicalReminders.results.map(activity => activity.patientId))
        );

        const patients = await practice.fetchContacts({
          filter: { types: [ContactType.Patient], ids: patientIds }
        });

        const contactEvents = await booking.getCalendarEvents({
          statuses: [CalendarEventStatus.Confirmed],
          attendees: patientIds
        });

        const patientEventsData = getPatientEventsData(
          contactEvents.results,
          patients.results
        );

        const nextAppointmentMap = new Map<string, string>();
        for (const patientData of patientEventsData) {
          if (patientData.upcomingAppointment) {
            const appointmentDate =
              patientData.upcomingAppointment.startDateTime.toDayDefaultFormat();
            nextAppointmentMap.set(patientData.patient.id, appointmentDate);
          } else {
            nextAppointmentMap.set(patientData.patient.id, "Not booked");
          }
        }

        // Get all of administration reminders by args.
        const activityIds = clinicalReminders.results.map(
          activity => activity.id
        );

        const administrationReminders =
          await clinical.getAdministrationReminders({
            activityIds
          });

        // TEMP Filtering: This is currently done to show that items have been affected and updated.
        // This should not be a final solution but should be done in the backend when creating the correct
        // Follow up reminders list.
        const requiredClinicalReminders = props.showAsFollowUp
          ? clinicalReminders.results.filter(x =>
              administrationReminders.some(
                y =>
                  y.clinicalActivityId === x.id && y.contactRecords.length > 1
              )
            )
          : clinicalReminders.results.filter(
              x =>
                !administrationReminders.find(
                  y => y.clinicalActivityId === x.id
                ) ||
                administrationReminders.find(
                  y =>
                    y.clinicalActivityId === x.id &&
                    y.contactRecords.length === 0
                )
            );

        const remindersWithPatients = requiredClinicalReminders
          .filter(x => !x.isCompleted) // Temporary filter until real filter
          .map((activity): ClinicalActivityTableRow => {
            const patient = practice.contactsMap.get(activity.patientId);
            const nextAppointmentDate =
              nextAppointmentMap.get(activity.patientId) || "Not booked";

            const administrationReminder = administrationReminders.find(
              x => x.clinicalActivityId === activity.id
            );

            return {
              id: activity.id,
              activity,
              patient,
              nextAppointmentDate,
              reminderContactRecords:
                administrationReminder?.contactRecords ?? undefined
            };
          });

        return { ...clinicalReminders, results: remindersWithPatients };
      },
      [clinical, filter, practice, booking, props.showAsFollowUp]
    );

    return (
      <ScrollablePane
        styles={{
          root: {
            height: "100%",
            padding: 8,
            position: "relative"
          }
        }}
      >
        <ClinicalRemindersTable
          showAsFollowUp={props.showAsFollowUp}
          isMultiline={true}
          setSelected={rows => {
            const selectedActivities = rows.map(row => row.activity);
            setSelectedClinicalActivities(selectedActivities);
            if (setSelected) {
              setSelected(selectedActivities);
            }
          }}
        >
          {({ selection, columns, renderRow, renderDetailsHeader }) => (
            <InfiniteScrollList
              setKey="clinical-reminders-list"
              selectionMode={SelectionMode.multiple}
              stickyHeader
              getItems={getItems}
              columns={columns}
              onRenderRow={renderRow}
              onRenderDetailsHeader={renderDetailsHeader}
              selection={selection.current}
              selectionPreservedOnEmptyClick={true}
              refreshKey={clinical.lastUpdatedClinicalActivities}
              onRenderNoResults={() => (
                <NoDataTile
                  textProps={{ text: "No information to display" }}
                  linkProps={{ hidden: true }}
                  showBoxShadow={false}
                />
              )}
            />
          )}
        </ClinicalRemindersTable>
      </ScrollablePane>
    );
  }
);

export const RemindersClinicalList: FC<RemindersClinicalProps> = props => (
  <Tile
    styles={{
      root: {
        flexGrow: 1,
        height: "100%",
        position: "relative"
      }
    }}
  >
    <Stack
      styles={{
        root: { height: "100%", overflowX: "auto" }
      }}
    >
      <ClinicalReminderFilter>
        <RemindersClinicalListBase {...props} />
      </ClinicalReminderFilter>
    </Stack>
  </Tile>
);
