import { BlockBlobClient } from "@azure/storage-blob";

export const getInitialsText = (businessName: string | undefined) => {
  if (!businessName) return;

  const words = businessName.split(" ");
  if (words.length > 1) {
    return `${words[0][0]}${words[1][0]}`;
  }
  return `${words[0][0]}${words[0][1]}`;
};

export const getFileFromUrl = async (name: string, practiceLogoUrl: string) => {
  const blobClient = new BlockBlobClient(practiceLogoUrl);
  const blobBody = await (await blobClient.download()).blobBody;
  if (blobBody) {
    const type = blobBody.type;
    const [, extension] = type.split("/");
    const file = new File([blobBody], `${name}.${extension}`, {
      type
    });
    return file;
  }
  return;
};
