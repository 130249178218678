import { NotificationsStore } from "stores/notifications/NotificationsStore.ts";

import { unique } from "@bps/utils";
import { Country } from "@libs/enums/country.enum.ts";
import { ProviderOnlineStatus } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import {
  CatalogBusinessRoleDto,
  Permission,
  SecurityAreaKeyword,
  SecurityInitialsKeyword,
  SecurityPermission,
  SecurityRoleCode,
  SecurityTypeKeyword
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { UserScheduleConfigured } from "@libs/utils/calendar/calendar.constants.ts";
import { BhbStore } from "@stores/bhb/BhbStore.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import { SecurityPermissionsValue } from "./UserSecurityRolesForm.types.ts";

export const getWorkingHoursLinkMessage = (
  scheduleConfigured: UserScheduleConfigured,
  showOnCalendar: boolean
): string => {
  if (!showOnCalendar) return "";
  switch (scheduleConfigured) {
    case UserScheduleConfigured.NotConfigured:
      return "Set working hours for this user to be searchable when booking appointments";
    case UserScheduleConfigured.Configured:
      return "User is configured to be searchable when booking appointments";
    case UserScheduleConfigured.Expired:
      return "Working hours have expired. Set new working hours for this user to be searchable when booking appointments.";
    default:
      return "";
  }
};

export const getExceptionsLinkMessage = (
  scheduleConfigured: UserScheduleConfigured,
  showOnCalendar: boolean
): string => {
  if (
    showOnCalendar &&
    scheduleConfigured !== UserScheduleConfigured.Configured
  ) {
    return "Exceptions section is disabled until working hours have been set";
  }

  return "";
};

export const saveBhbProviderOnlineStatus = async (
  bhbProvider: BhbProvider | undefined,
  options: {
    core: CoreStore;
    bhb: BhbStore;
  }
) => {
  const { core, bhb } = options;

  if (
    bhbProvider &&
    core.hasPermissions([Permission.BhbConfigAllowed, Permission.BhbWrite])
  ) {
    const updatedProvider = {
      ...bhbProvider.dto,
      providerOnlineStatus: ProviderOnlineStatus.NO
    };

    await bhb.updateProviderForLocation(updatedProvider, core.locationId); // temporary
  }
};

export const updateShowOnCalendarValue = async (
  userId: string,
  userName: string,
  options: {
    userExperience: UserExperienceStore;
    core: CoreStore;
    notification: NotificationsStore;
    checked: boolean | undefined;
  }
) => {
  const { checked, userExperience, notification } = options;

  userExperience
    .upsertUserSetting({ userId, showOnCalendar: !!checked })
    .then(() => {
      notification.success(
        `${userName} Appointment book settings have been updated.`
      );
    })
    .catch(() => {
      notification.error(`${userName} Appointment book settings not updated.`);
    });
};

export const getSecurityRoleCode = (
  securityArea: SecurityAreaKeyword,
  securityType: SecurityTypeKeyword
): SecurityRoleCode =>
  `${SecurityInitialsKeyword.Titanium}.${securityArea}-${securityType}` as SecurityRoleCode;

export const transformToSecurityRoles = (
  values: Partial<SecurityPermissionsValue>
): string[] => {
  const securityRoles: string[] = [];
  Object.entries(values).forEach(entry => {
    const [securityArea, securityType] = entry;
    if (
      securityArea &&
      securityType &&
      securityType !== SecurityTypeKeyword.None
    ) {
      securityRoles.push(
        getSecurityRoleCode(securityArea as SecurityAreaKeyword, securityType)
      );
    }
  });
  return securityRoles;
};

export const transformFromSecurityRoles = (
  securityRoles: string[]
): Partial<SecurityPermissionsValue> => {
  const securityPermissionsValue: Partial<SecurityPermissionsValue> = {};
  securityRoles.forEach(securityRole => {
    const decodedSecurityRole = securityRole
      .replace(`${SecurityInitialsKeyword.Titanium}.`, "")
      .split("-");
    if (decodedSecurityRole.length >= 2) {
      const [securityArea, securityType] = decodedSecurityRole;
      securityPermissionsValue[securityArea] = securityType;
    }
  });
  return securityPermissionsValue;
};

export const getInitialSecurityPermissionsValue = (
  securityPermissions: SecurityPermission[],
  securityRoles: string[]
): Partial<SecurityPermissionsValue> => {
  const securityRole: Partial<SecurityPermissionsValue> =
    transformFromSecurityRoles(securityRoles);

  const securityAreas = Object.keys(securityRole);
  securityPermissions.forEach(securityPermission => {
    const { area } = securityPermission;
    if (!securityAreas.includes(area)) {
      securityRole[area] = SecurityTypeKeyword.None;
    }
  });

  return securityRole;
};

export const getDefaultSecurityPermissions = (
  isNZTenant: boolean,
  isBhbEnabled: boolean,
  isLicencingEnabled: boolean
) => {
  const defaultSecurityPermissions: SecurityPermission[] = [
    {
      area: SecurityAreaKeyword.Licencing,
      areaLabel: "Licensed workflows"
    },
    {
      area: SecurityAreaKeyword.UserManagement,
      areaLabel: "User management"
    },
    {
      area: SecurityAreaKeyword.PracInfo,
      areaLabel: "Practice information"
    },
    {
      area: SecurityAreaKeyword.PracWork,
      areaLabel: "Practice workflows"
    },
    {
      area: SecurityAreaKeyword.Appointments,
      areaLabel: "Appointments"
    },
    {
      area: SecurityAreaKeyword.Accounts,
      areaLabel: "Accounts"
    },
    {
      area: SecurityAreaKeyword.Claiming,
      areaLabel: "Claiming"
    },
    {
      area: SecurityAreaKeyword.Clinical,
      areaLabel: "Clinical"
    },
    {
      area: SecurityAreaKeyword.Report,
      areaLabel: "Reports"
    },
    {
      area: SecurityAreaKeyword.Bhb,
      areaLabel: "Best Health Booking"
    }
  ];

  return defaultSecurityPermissions.filter(securityPermission => {
    if (
      (!isNZTenant &&
        securityPermission.area === SecurityAreaKeyword.Claiming) ||
      (!isBhbEnabled && securityPermission.area === SecurityAreaKeyword.Bhb) ||
      (!isLicencingEnabled &&
        securityPermission.area === SecurityAreaKeyword.Licencing)
    ) {
      return false;
    }
    return securityPermission;
  });
};

export const getSecurityPermissionMatrix = (
  defaultSecRoleCodes: SecurityRoleCode[],
  securityPermissions: SecurityPermission[],
  licensedWfContributor: boolean
): Partial<SecurityPermissionsValue> => {
  const rankedSecurityTypes = [
    SecurityTypeKeyword.Administrator,
    SecurityTypeKeyword.Contributor,
    SecurityTypeKeyword.Read
  ];

  const securityPermissionsValue = {};
  securityPermissions.forEach(securityPermission => {
    for (const securityType of rankedSecurityTypes) {
      const { area } = securityPermission;
      securityPermissionsValue[area] = SecurityTypeKeyword.None;
      if (
        defaultSecRoleCodes.includes(getSecurityRoleCode(area, securityType))
      ) {
        securityPermissionsValue[area] = securityType;
        break;
      }
    }
  });

  if (!!securityPermissionsValue[SecurityAreaKeyword.Licencing]) {
    securityPermissionsValue[SecurityAreaKeyword.Licencing] =
      licensedWfContributor
        ? SecurityTypeKeyword.Contributor
        : SecurityTypeKeyword.None;
  }
  return securityPermissionsValue;
};

export const getDefaultSecurityRoleCodes = (
  catalogBusinessRoles: CatalogBusinessRoleDto[],
  selectedBusinesRoles: string[],
  tenantCountry?: Country
) => {
  let defaultSRCodes: SecurityRoleCode[] = [];

  catalogBusinessRoles.forEach(catalogBusinessRole => {
    const businessRole = selectedBusinesRoles.find(
      (code: string) => code === catalogBusinessRole.code
    );
    if (businessRole) {
      const tenantProfile = catalogBusinessRole?.profiles?.find(
        profile => profile.countryCode === tenantCountry
      );

      defaultSRCodes = [
        ...defaultSRCodes,
        ...(tenantProfile?.defaultSecurityRoleCodes || [])
      ];
    }
  });

  return unique(defaultSRCodes);
};
