import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { PatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";
import { Discharge } from "./Discharge.tsx";

interface ConditionDischargesProps {
  model: ConditionDetailsModel;
}

export const ConditionDischarges: React.FC<ConditionDischargesProps> = ({
  model
}) => {
  const { clinical, core } = useStores();

  const { clinicalRecord, getLatestActiveDischargeClinicalDataItem } =
    useContext(PatientRecordScreenContext);

  const getLinkedEocProviderBusinessRoles = async () => {
    let encounters: Encounter[] = [];
    const episodeOfCareId = model.condition?.episodeOfCareId;
    encounters = await clinical.getEncounters({
      patientId: model.condition?.patientId ?? "",
      episodeOfCareIds: episodeOfCareId ? [episodeOfCareId] : undefined
    });

    const providerBusinessRoleCodes = core.catalogBusinessRoles
      .filter(x => x.classCode === BusinessRoleClasses.Provider)
      .map(x => x.code);

    const businessRoles = encounters
      ?.filter(
        x =>
          x.businessRole && providerBusinessRoleCodes.includes(x.businessRole)
      )
      ?.map(x => x.businessRole);

    return Array.from(new Set(businessRoles));
  };

  const fetchData = async () => {
    const linkedBusinessRoles = await getLinkedEocProviderBusinessRoles();

    await clinicalRecord.loadCurrentConditionDischargeData(
      model.condition?.episodeOfCareId
    );

    const businessRoles = multiProviderEnabled
      ? [...linkedBusinessRoles]
      : [linkedBusinessRoles[0]];

    return businessRoles;
  };

  const multiProviderEnabled = core.hasPermissions(
    Permission.MultiProviderClaimsAllowed
  );

  return (
    <DataFetcher
      fetch={fetchData}
      refetchId={clinicalRecord.currentConditionDischargeDataItems?.length}
    >
      {businessRoles => {
        if (businessRoles.length > 0) {
          const showBusinessRole =
            multiProviderEnabled && businessRoles.length > 1;

          return (
            <Stack>
              {businessRoles?.map(role => {
                const dataItem = getLatestActiveDischargeClinicalDataItem(
                  role,
                  clinicalRecord.currentConditionDischargeDataItems
                );

                return (
                  <Discharge
                    key={role}
                    showBusinessRole={showBusinessRole}
                    dataItem={dataItem}
                    condition={model.condition!}
                  />
                );
              })}
            </Stack>
          );
        } else {
          return null;
        }
      }}
    </DataFetcher>
  );
};
