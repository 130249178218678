import { observer } from "mobx-react-lite";
import { useField, useForm, useFormState } from "react-final-form";

import { CenteredLargeSpinner, FontWeights, Stack, Text } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, Duration, TIME_FORMATS } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { ClaimPickerField } from "@modules/acc/screens/shared-components/claim-picker/ClaimPickerField.tsx";
import { ConfidentialToggleField } from "@modules/clinical/screens/document-writer/components/ConfidentialToggleField.tsx";
import { RecordUpdateCheckedLog } from "@modules/clinical/screens/shared-components/RecordUpdateCheckedLog.tsx";
import { ClinicalActivityFormValues } from "@shared-types/clinical/clinical-activity-values.type.ts";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { ComboBoxField } from "@ui-components/form/ComboBoxField.tsx";
import {
  DatePickerOnInField,
  useDatePickerOnInField
} from "@ui-components/form/DatePickerOnInField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { PatientPickerField } from "@ui-components/pickers/contact-picker/PatientPickerField.tsx";
import { UserPickerField } from "@ui-components/pickers/user-picker/UserPickerField.tsx";

import { ClinicalTaskDue } from "../clinical-task/types/clinical-task-due.enum.ts";
import { ClinicalActivityDue } from "./types/clinical-activity-due.type.ts";
import { ClinicalActivityType } from "./types/clinical-activity.type.ts";
import { checkIsDescriptionClaimRelated } from "./utils.ts";

const nameOf = nameOfFactory<ClinicalActivityFormValues>();

export interface ClinicalActivityFormProps {
  clinicalActivity?: ClinicalActivity;
  hideActivityTypeOption?: boolean;
  allowProviderSelection?: boolean;
  allowPatientSelection?: boolean;
}

enum Labels {
  claim = "Claim",
  type = "Type",
  description = "Description",
  reason = "Reason",
  due = "Due",
  priority = "Priority",
  comment = "Comment",
  lockText = "Locked. Only I can make changes to this task"
}

const ClinicalActivityFormFieldsComponent: React.FC<ClinicalActivityFormProps> =
  observer(
    ({
      clinicalActivity,
      hideActivityTypeOption,
      allowProviderSelection,
      allowPatientSelection
    }) => {
      const { clinical, core } = useStores();
      const form = useForm<ClinicalActivityFormValues>();
      const { change } = form;
      const { values: formValues } = useFormState<ClinicalActivityFormValues>();

      const {
        input: { value: activityType }
      } = useField(nameOf("activityType"));

      const isReminder = activityType === ClinicalActivityType.Reminder;
      const isTask = activityType === ClinicalActivityType.Task;

      const activityDescriptions = clinical.activityDescriptionMapValues;

      const allowReminderFreeText =
        clinical.clinicalActivitiesPreference?.allowReminderFreeText;

      const getDescriptionOptions = () => {
        const descriptionOptions = activityDescriptions.filter(
          x => !x.isInactive && x.activityType === activityType
        );

        const stortedClinicalReminderReasonsOptions = Array.from(
          descriptionOptions
        ).sort((a, b) => a.reasonText.localeCompare(b.reasonText));

        return stortedClinicalReminderReasonsOptions.map(x => {
          return { key: x.id, text: x.reasonText };
        });
      };

      const getDescriptionName = () => {
        if (isReminder) {
          return Labels.reason;
        }

        return Labels.description;
      };

      const getDescriptionPlaceholder = () => {
        return `Select ${isTask || allowReminderFreeText ? "or type a" : "a"} ${
          isTask ? "description" : "reason"
        }`;
      };

      const getCommentName = () => {
        if (isReminder) {
          return "Internal comment";
        }

        return "Comment";
      };

      const updateDate = (duration: Duration) => {
        const newDate = DateTime.now().startOf("day").plus(duration);
        change(nameOf("dueDate"), newDate.toJSDate());
      };

      const controlProps = useDatePickerOnInField(updateDate);

      const {
        setDatePickerButtonValue,
        setDatePickerIncrementValue,
        updateDateFromControl
      } = controlProps;

      const options = getDescriptionOptions().filter(x => x.text !== "Other");

      const activityDescription = activityDescriptions.find(
        x => x.id === formValues.descriptionId
      );

      const otherRequired =
        activityDescription &&
        activityDescription.convertedDescriptionCode === "OTH";

      const isDescriptionClaimRelated =
        checkIsDescriptionClaimRelated(activityDescription);

      const setDefaultDate = (value: string) => {
        const clinicalReminderReason = activityDescriptions?.find(
          x => x.id === value
        );

        const interval = clinicalReminderReason?.interval?.toString() ?? "";
        const frequency = clinicalReminderReason?.frequency ?? "";

        setDatePickerIncrementValue(interval);
        setDatePickerButtonValue(frequency);
        updateDateFromControl(frequency, interval);
      };

      return (
        <>
          <Stack tokens={{ childrenGap: 8 }}>
            {!hideActivityTypeOption && (
              <Stack.Item>
                <ButtonsGroupSingleChoiceField
                  disabled={formValues.isSystemGenerated || !!clinicalActivity}
                  label={Labels.type}
                  name={nameOf("activityType")}
                  required
                  notUnselectable
                  options={clinical.ref.clinicalActivityTypes.keyTextValues}
                />

                <FieldSpy
                  name={nameOf("activityType")}
                  onChange={() => {
                    setDatePickerIncrementValue(undefined);
                    setDatePickerButtonValue(undefined);
                    form.batch(() => {
                      form.change(nameOf("dueDate"), undefined);
                      if (form.getFieldState(nameOf("dueDate"))) {
                        form.resetFieldState(nameOf("dueDate"));
                      }
                    });
                  }}
                />
              </Stack.Item>
            )}

            {allowPatientSelection && (
              <PatientPickerField
                label="Patient"
                name={nameOf("patientId")}
                required
              />
            )}

            {allowProviderSelection && (
              <UserPickerField
                currentUserOnTop
                label="Provider"
                name={nameOf("userId")}
                required
              />
            )}

            {(isReminder || isTask) && (
              <>
                <Stack.Item>
                  {isTask || !!allowReminderFreeText ? (
                    <ComboBoxField
                      name={nameOf("descriptionId")}
                      options={options}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      label={getDescriptionName()}
                      disabled={formValues.isSystemGenerated || !activityType}
                      required
                      placeholder={getDescriptionPlaceholder()}
                      calloutProps={{
                        calloutMaxHeight: 500
                      }}
                      dynamicOptions
                    />
                  ) : (
                    <DropdownField
                      disabled={formValues.isSystemGenerated || !activityType}
                      label={getDescriptionName()}
                      name={nameOf("descriptionId")}
                      required
                      options={options}
                      withNoEmptyOption
                      calloutProps={{
                        calloutMaxHeight: 500
                      }}
                      placeholder={getDescriptionPlaceholder()}
                    />
                  )}

                  <FieldSpy
                    name={nameOf("descriptionId")}
                    onChange={value => {
                      if (!options.map(x => x.key).includes(value)) {
                        form.change(nameOf("freeText"), value);
                      } else {
                        form.change(nameOf("freeText"), undefined);
                      }

                      if (
                        form.getFieldState(nameOf("dueChoice"))?.value !==
                        ClinicalActivityDue.Consult
                      ) {
                        form.change(
                          nameOf("dueChoice"),
                          ClinicalActivityDue.Date
                        );

                        setDefaultDate(value);
                      }
                    }}
                  />
                </Stack.Item>
                {isTask && isDescriptionClaimRelated && (
                  <Stack.Item>
                    <ClaimPickerField
                      label={Labels.claim}
                      name={nameOf("taskSelectedClaim")}
                      required
                      disabled={formValues.isSystemGenerated}
                      patientId={clinical.activeRecordPatientId}
                      showBusinessRole={true}
                      pickByClaimNumber
                    />
                  </Stack.Item>
                )}

                <Stack
                  tokens={{ childrenGap: 8 }}
                  styles={{ root: { paddingBottom: 8 } }}
                  horizontal
                  verticalAlign="start"
                >
                  <ButtonsGroupSingleChoiceField
                    label={Labels.due}
                    name={nameOf("dueChoice")}
                    required
                    disabled={formValues.isSystemGenerated}
                    options={[
                      {
                        key: ClinicalActivityDue.Date,
                        text: "Date"
                      },
                      {
                        key: ClinicalActivityDue.Consult,
                        text: "Consult count"
                      }
                    ]}
                  />

                  <FieldCondition
                    when={nameOf("dueChoice")}
                    is={ClinicalActivityDue.Date}
                  >
                    <DatePickerOnInField
                      name={nameOf("dueDate")}
                      label="Date"
                      controlProps={controlProps}
                    />
                  </FieldCondition>

                  <FieldCondition
                    when={nameOf("dueChoice")}
                    is={ClinicalActivityDue.Consult}
                  >
                    <SpinNumberInputField
                      label="After"
                      name={nameOf("dueInVisits")}
                      min={1}
                      max={99}
                      parse={value => Number(value)}
                      styles={{ root: { width: 90 } }}
                      fieldItemStyles={{ root: { width: 90 } }}
                      suffix={<div>consults</div>}
                    />
                  </FieldCondition>
                </Stack>

                <FieldCondition
                  when={nameOf("activityType")}
                  is={ClinicalActivityType.Task}
                >
                  <Stack.Item>
                    <ButtonsGroupSingleChoiceField
                      label={Labels.priority}
                      name={nameOf("activityPriority")}
                      required
                      disabled={formValues.isSystemGenerated}
                      options={clinical.ref.priorities.keyTextValues}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <ConfidentialToggleField name={nameOf("confidential")} />
                  </Stack.Item>
                </FieldCondition>

                <Stack.Item>
                  <TextInputField
                    label={getCommentName()}
                    name={nameOf("comment")}
                    placeholder="250 characters max"
                    resizable={false}
                    rows={4}
                    maxLength={250}
                    required={otherRequired}
                    multiline
                  />
                </Stack.Item>
                <FieldCondition
                  when={nameOf("activityType")}
                  is={ClinicalActivityType.Task}
                >
                  <Stack.Item>
                    <CheckboxField
                      label={Labels.lockText}
                      name={nameOf("isLocked")}
                      disabled={
                        formValues.isSystemGenerated ||
                        !(
                          (formValues.lockedBy
                            ? formValues.lockedBy === core.userId
                            : true) ||
                          core.permissions.includes(Permission.ClinTaskUnlock)
                        )
                      }
                    />
                  </Stack.Item>
                </FieldCondition>
                {formValues.isSystemGenerated &&
                  clinicalActivity?.changeLog?.createdDate && (
                    <Stack.Item>
                      <Text
                        styles={{ root: { fontWeight: FontWeights.semibold } }}
                      >
                        System generated
                      </Text>
                      {` on ${DateTime.fromISO(
                        clinicalActivity.changeLog.createdDate
                      ).toFormat(
                        DATE_FORMATS.DAY_TEXT_MONTH_YEAR
                      )} at ${DateTime.fromISO(
                        clinicalActivity.changeLog.createdDate
                      ).toFormat(TIME_FORMATS.DEFAULT_TIME_FORMAT)}`}
                    </Stack.Item>
                  )}
                {!formValues.isSystemGenerated &&
                  clinicalActivity?.changeLog?.createdBy && (
                    <RecordUpdateCheckedLog
                      createdBy={clinicalActivity.changeLog.createdBy}
                      createdDate={clinicalActivity.changeLog.createdDate}
                      updatedBy={clinicalActivity.changeLog.updatedBy}
                      updatedDate={clinicalActivity.changeLog.updatedDate}
                      styles={{
                        root: {
                          justifyContent: "flex-start",
                          paddingTop: 8
                        }
                      }}
                    />
                  )}
              </>
            )}
          </Stack>
          <FieldSpy
            name={nameOf("isLocked")}
            onChange={value => {
              form.change(nameOf("lockedBy"), value ? core.userId : undefined);
            }}
          />
          <FieldSpy
            name={nameOf("dueChoice")}
            onChange={(
              value: ClinicalActivityFormValues["dueChoice"],
              values: ClinicalActivityFormValues
            ) => {
              if (value === ClinicalTaskDue.Date) {
                form.change(nameOf("dueInVisits"), 0);
                const currentDescription = values.descriptionId;
                currentDescription && setDefaultDate(currentDescription);
              } else {
                form.change(nameOf("dueDate"), undefined);
              }
            }}
          />
          <FieldSpy
            name={nameOf("activityType")}
            onChange={() => {
              form.batch(() => {
                form.change(nameOf("descriptionId"), undefined);
                form.resetFieldState(nameOf("descriptionId"));
              });
            }}
          />

          <FieldSpy
            name={nameOf("confidential")}
            onChange={(value: boolean) => {
              form.change(nameOf("isLocked"), value);
            }}
          />
          <FieldSpy
            name={nameOf("descriptionId")}
            onChange={() => {
              if (!isDescriptionClaimRelated) {
                form.change(nameOf("taskSelectedClaim"), undefined);
              }
            }}
          />
        </>
      );
    }
  );

export const ClinicalActivityFormFields = withFetch(
  x => [
    x.clinical.ref.priorities.load(),
    x.clinical.ref.clinicalActivityTypes.load(),
    x.clinical.ref.clinicalActivityDescriptions.load(),
    x.clinical.getClinicalActivitiesPreferenceByTenant({ ignoreCache: true }),
    x.clinical.loadActivityDescriptions()
  ],
  ClinicalActivityFormFieldsComponent,
  {
    fallback: (
      <CenteredLargeSpinner
        centeredBoxProps={{ styles: { root: { height: 480 } } }}
      />
    )
  }
);
