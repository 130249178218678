export enum ClinicalReminderTableLabels {
  Patient = "Patient",
  Reminder = "Reminder",
  Due = "Due",
  Provider = "Provider",
  PatientDetails = "Patient details",
  NextAppt = "Next appt.",
  Created = "Created",
  TempSentStatus = "TemptSentStatus"
}
