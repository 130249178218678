import { observer } from "mobx-react-lite";
import { FormSpy } from "react-final-form";

import { MessageBar, MessageBarType, Separator, Stack } from "@bps/fluent-ui";
import { BhbLocation } from "@stores/bhb/models/BhbLocation.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import { BhbBookingUrl } from "./BhbBookingUrl.tsx";
import { BhbConfigurationFormValidator } from "./BhbConfigurationFormValidator.ts";
import { bhbConfigurationFormNameOf } from "./BhbConfigurationSettingsForm.types.ts";
import { BhbConfigurationSettingsFormModel } from "./BhbConfigurationSettingsFormModel.ts";

const PLACEHOLDER_TEXT = "Nothing will be displayed if not specified";

interface BhbConfigurationSettingsFormProps {
  location: BhbLocation;
  setIsDirty: (dirty: boolean) => void;
}

export const BhbConfigurationSettingsForm = observer(
  ({ location, setIsDirty }: BhbConfigurationSettingsFormProps) => {
    const root = useStores();

    const model = new BhbConfigurationSettingsFormModel(root, location);

    return (
      <SubmissionForm
        formName="bhb-configuration"
        initialValues={model.getInitialValues()}
        onSubmit={model.onSubmit}
        validate={values =>
          new BhbConfigurationFormValidator().validate(values)
        }
      >
        {() => (
          <Stack styles={{ root: { maxWidth: 630 } }}>
            <ToggleField
              label="Best Health Booking"
              name={bhbConfigurationFormNameOf("onlineBookingsEnabled")}
              onText="Enabled"
              offText="Disabled"
            />
            <FieldCondition
              when={bhbConfigurationFormNameOf("onlineBookingsEnabled")}
              is={false}
            >
              <MessageBar messageBarType={MessageBarType.warning}>
                Please enable the online booking to display your practice
                information online
              </MessageBar>
            </FieldCondition>
            <BhbBookingUrl locationId={location.id} />
            <Separator />
            <TextInputField
              name={bhbConfigurationFormNameOf("displayName")}
              label="Display name"
            />
            <TextInputField
              name={bhbConfigurationFormNameOf("parkingAccess")}
              label="Parking"
              multiline
              rows={3}
              maxLength={500}
              placeholder={PLACEHOLDER_TEXT}
            />
            <TextInputField
              name={bhbConfigurationFormNameOf("disabilityAccess")}
              label="Disability access"
              multiline
              rows={3}
              maxLength={500}
              placeholder={PLACEHOLDER_TEXT}
            />
            <FormSpy
              subscription={{ dirty: true }}
              onChange={({ dirty }) => setIsDirty(dirty)}
            />
          </Stack>
        )}
      </SubmissionForm>
    );
  }
);
