import { observer } from "mobx-react-lite";
import { FunctionComponent, ReactNode, useRef } from "react";

import {
  DetailsRow,
  FontSizes,
  FontWeights,
  IColumn,
  IDetailsHeaderProps,
  IDetailsRowProps,
  noWrap,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { compareDatesPredicate, DateTime } from "@bps/utils";
import { ClinicalActivity } from "@stores/clinical/models/ClinicalActivity.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { ActivityDescriptionText } from "../clinical-activity/ActivityDescriptionText.tsx";
import { ConfidentialToolTipFontIcon } from "../ConfidentialToolTipFontIcon.tsx";
import { PatientContactCell } from "./PatientContactCell.tsx";
import { ClinicalActivityTableRow } from "./types/clinical-activity-table.type.ts";
import { ClinicalReminderTableLabels } from "./types/clinical-reminder-table-labels.enum.ts";

export interface ChildrenProps {
  selection: React.MutableRefObject<
    Selection<
      ClinicalActivityTableRow & {
        key: string;
      }
    >
  >;
  columns: IColumn[];
  renderRow: (props: IDetailsRowProps) => JSX.Element | null;
  renderDetailsHeader: (
    props: IDetailsHeaderProps,
    defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
  ) => JSX.Element;
}

export interface ClinicalRemindersTableProps {
  isMultiline?: boolean;
  showAsFollowUp?: boolean;
  children: (props: ChildrenProps) => ReactNode;
  setSelected?: (selected: ClinicalActivityTableRow[]) => void;
}

const ClinicalRemindersTableBase: FunctionComponent<ClinicalRemindersTableProps> =
  observer((props: ClinicalRemindersTableProps) => {
    const theme = useTheme();
    const {
      practice: {
        ui: { showContactDetails }
      }
    } = useStores();

    const { setSelected, children } = props;

    const isItemSelectable = (item: ClinicalActivityTableRow) => {
      return item.activity.isCompleted !== true;
    };

    const selection = useRef(
      new Selection({
        canSelectItem: (item: ClinicalActivityTableRow & { key: string }) =>
          isItemSelectable(item),
        onSelectionChanged: async () => {
          if (setSelected) {
            const selectedTasks =
              selection.current.getSelection() as ClinicalActivityTableRow[];
            setSelected(selectedTasks);
          }
        }
      })
    );

    const toDueDateString = (
      dueDate: string | undefined,
      dueInVisits: number | undefined
    ) => {
      if (dueDate) return DateTime.fromISO(dueDate).toDayDefaultFormat();
      if (dueInVisits)
        return `${dueInVisits} consult${dueInVisits > 1 ? "s" : ""}`;
      return "";
    };

    const toPatientText = (patient?: Contact) => {
      if (!patient) return "";

      const dobString = patient.birthDate
        ? `DOB: ${patient.birthDate.toDayDefaultFormat()}`
        : "No DOB recorded";
      return (
        <Stack>
          <Navigate onClick={() => showContactDetails(patient!.id)}>
            {`${patient.lastName}, ${patient.firstName}`}
          </Navigate>
          <Text styles={{ root: { fontSize: "12px" } }}>{dobString}</Text>
        </Stack>
      );
    };

    const toReminderText = (reminder: ClinicalActivityTableRow) => {
      return (
        <ActivityDescriptionText
          descriptionId={reminder.activity.descriptionId}
          freeTextFallback={reminder.activity.freeText}
        />
      );
    };

    const toProviderText = (reminder: ClinicalActivityTableRow) => {
      return (
        <Stack>
          <Text>Doctor (TBC) {reminder.activity.changeLog?.createdBy}</Text>
          <Text styles={{ root: { fontSize: "12px" } }}>Location</Text>
        </Stack>
      );
    };

    const renderSentStatus = (reminder: ClinicalActivityTableRow) => {
      // TEMP: This layout is temporary and is to be expanded upon in future work.
      // This exists now to prove that the information was sent.

      if (reminder.reminderContactRecords) {
        const latestContactLatest = reminder.reminderContactRecords?.sort(
          (a, b) =>
            compareDatesPredicate(
              DateTime.fromISO(b.timeStamp),
              DateTime.fromISO(a.timeStamp)
            )
        );

        const latest = latestContactLatest[0];

        if (!latest) {
          return undefined;
        }

        return <>{latest.status}</>;
      }

      return undefined;
    };

    const columns: IColumn[] = [
      {
        key: ClinicalReminderTableLabels.Patient,
        minWidth: 170,
        maxWidth: 170,
        name: ClinicalReminderTableLabels.Patient,
        isMultiline: true,
        onRender: (item: ClinicalActivityTableRow) => {
          return toPatientText(item.patient);
        }
      },
      {
        key: ClinicalReminderTableLabels.Reminder,
        minWidth: 150,
        maxWidth: 300,
        targetWidthProportion: 3,
        name: ClinicalReminderTableLabels.Reminder,
        isMultiline: true,
        onRender: (item: ClinicalActivityTableRow) => {
          return toReminderText(item);
        }
      },
      {
        key: ClinicalReminderTableLabels.Due,
        minWidth: 100,
        maxWidth: 100,
        name: ClinicalReminderTableLabels.Due,
        isMultiline: false,
        onRender: (item: ClinicalActivityTableRow) => (
          <Text>
            {toDueDateString(item.activity.dueDate, item.activity.dueInVisits)}
          </Text>
        )
      }
    ];

    if (props.showAsFollowUp) {
      columns.push({
        key: ClinicalReminderTableLabels.TempSentStatus,
        name: ClinicalReminderTableLabels.TempSentStatus,
        minWidth: 50,
        maxWidth: 50,
        onRender: (item: ClinicalActivityTableRow) => {
          return renderSentStatus(item);
        }
      });
    }

    columns.push(
      ...[
        {
          key: ClinicalReminderTableLabels.Provider,
          name: ClinicalReminderTableLabels.Provider,
          minWidth: 160,
          maxWidth: 160,
          onRender: (item: ClinicalActivityTableRow) => {
            return toProviderText(item);
          }
        },
        {
          key: ClinicalReminderTableLabels.PatientDetails,
          name: ClinicalReminderTableLabels.PatientDetails,
          minWidth: 200,
          targetWidthProportion: 4,
          isCollapsible: true,
          onRender: (item: ClinicalActivityTableRow) => {
            return <PatientContactCell patient={item.patient} />;
          }
        },
        {
          key: ClinicalReminderTableLabels.NextAppt,
          name: ClinicalReminderTableLabels.NextAppt,
          minWidth: 120,
          maxWidth: 120,
          onRender: (item: ClinicalActivityTableRow) => {
            return <Text>{item.nextAppointmentDate}</Text>;
          }
        },
        {
          key: ClinicalReminderTableLabels.Created,
          minWidth: 160,
          maxWidth: 160,
          name: ClinicalReminderTableLabels.Created,
          isMultiline: true,
          onRender: (item: ClinicalActivityTableRow) => {
            return getCreatedByText(item.activity);
          }
        }
      ]
    );

    const getCreatedByText = (item: ClinicalActivity) => {
      const username = item.changeLog?.createdBy;
      const createDate = DateTime.fromISO(
        item.changeLog?.createdDate
      )?.toDayDefaultFormat();
      if (username === User.System) {
        return <TooltipHost content={username}>{username}</TooltipHost>;
      } else if (username) {
        return (
          <UserFetcher username={username}>
            {user => {
              return (
                <Stack>
                  <Text>{createDate}</Text>
                  <Text variant="small">by {user.fullName}</Text>
                </Stack>
              );
            }}
          </UserFetcher>
        );
      }

      return undefined;
    };

    const renderRow = (props: IDetailsRowProps): JSX.Element | null => {
      const item = props.item as ClinicalActivityTableRow;
      if (item.activity.isCompleted) return null;

      const renderRowProps = { ...props };

      const disableRow = !isItemSelectable(item);

      if (disableRow) {
        renderRowProps.onRenderCheck = () => (
          <ConfidentialToolTipFontIcon isShowConfidentialIcon summaryStyling />
        );
      }

      return (
        <DetailsRow
          {...renderRowProps}
          styles={{
            root: {
              fontSize: FontSizes.size14,
              fontWeight: FontWeights.regular,
              "&.is-selected:hover": {
                backgroundColor: theme.semanticColors.listItemBackgroundChecked
              }
            },
            fields: {
              display: "inline-flex",
              alignItems: "center"
            },
            checkCell: {
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: 48
            },
            isMultiline: {
              ...noWrap
            }
          }}
          disabled={disableRow}
        />
      );
    };

    const renderDetailsHeader = (
      props: IDetailsHeaderProps,
      defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element
    ) => {
      return defaultRender!({
        ...props!,
        styles: {
          root: {
            "div.ms-DetailsHeader-check .ms-Check": { display: "none" }
          }
        }
      });
    };

    return (
      <>{children({ columns, selection, renderRow, renderDetailsHeader })}</>
    );
  });

export const ClinicalRemindersTable = withFetch(
  x => [x.clinical.loadActivityDescriptions()],
  ClinicalRemindersTableBase
);
