import { observer } from "mobx-react-lite";
import { GroupedList, Pivot, PivotItem } from "office-ui-fabric-react";
import { useState } from "react";

import {
  Heading,
  IconButton,
  IGroupHeaderProps,
  Spinner,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import {
  ClinicalDataType,
  ClinicalDataTypeLabel
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { MeasurementsGroupedView } from "./MeasurementsGroupedView .tsx";
import { ObservationHeader } from "./ObservationHeader.tsx";
import {
  getGroupMeasurements,
  getGroupsAndItems,
  GroupedMeasurement,
  GroupedMeasurementItem,
  groupObservationsByDateTypeAndTimeStamp,
  groupObservationsByExaminationAndDate,
  ObservationGroup
} from "./utils.ts";

interface ObservationsSidePanelProps {
  clinicalRecord: ClinicalRecord;
}

export const ObservationsSidePanel: React.FC<ObservationsSidePanelProps> =
  observer(({ clinicalRecord }) => {
    const { clinical } = useStores();
    const handleOnShowAsTableClick = () => {
      clinical.ui.setPatientClinicalContent({
        type: ClinicalDataType.Observations
      });
    };

    const [selectedPivotKey, setSelectedPivotKey] = useState<string>(
      ObservationGroup.GroupByDateAndTypeKey
    );

    const handlePivotItemClick = (item?: PivotItem) => {
      if (item?.props.itemKey) {
        setSelectedPivotKey(item.props.itemKey);
      }
    };

    const fetchMeasurements = async () => {
      const observationsResult = await clinicalRecord.loadObservationData();

      const observations = observationsResult.results;

      const groupedObservationsByDate =
        groupObservationsByDateTypeAndTimeStamp(observations);

      const groupedObservationsByExamination =
        groupObservationsByExaminationAndDate(observations);

      return { groupedObservationsByDate, groupedObservationsByExamination };
    };

    const onRenderCell = (
      nestingDepth?: number,
      item?: GroupedMeasurementItem
    ): React.ReactNode => {
      if (!item) return null;

      const groupedMeasurements = getGroupMeasurements(item, selectedPivotKey);

      return (
        <Stack styles={{ root: { marginLeft: 32, paddingBottom: 4 } }}>
          <MeasurementsGroupedView
            pivotKey={selectedPivotKey}
            groupedMeasurements={groupedMeasurements}
          />
        </Stack>
      );
    };

    const renderHeader = (
      props: IGroupHeaderProps,
      observations: Record<string, Record<string, GroupedMeasurement[]>>
    ) => {
      if (props && props.group) {
        return (
          <ObservationHeader
            props={props}
            clinicalRecord={clinicalRecord}
            selectedKey={selectedPivotKey}
            observations={observations}
          />
        );
      }
      return null;
    };

    return (
      <>
        <Stack horizontal>
          <Heading
            variant="section-heading-light"
            styles={{
              root: { paddingRight: 4, paddingTop: 2 }
            }}
          >
            {ClinicalDataTypeLabel.Observations}
          </Heading>
          <TooltipHost content={ObservationGroup.ShowAsTableLabel}>
            <IconButton
              iconProps={{ iconName: "GUID" }}
              onClick={() => handleOnShowAsTableClick()}
              styles={{ icon: { height: "17px" } }}
            />
          </TooltipHost>
        </Stack>
        <Stack styles={{ root: { minHeight: 0 } }}>
          <Pivot
            selectedKey={selectedPivotKey}
            onLinkClick={handlePivotItemClick}
          >
            <PivotItem
              itemKey={ObservationGroup.GroupByDateAndTypeKey}
              headerText={ObservationGroup.GroupByDateAndTypeLabel}
            />
            <PivotItem
              itemKey={ObservationGroup.GroupByExaminationAndDateKey}
              headerText={ObservationGroup.GroupByExaminationAndDateLabel}
            />
          </Pivot>

          <DataFetcher
            fetch={fetchMeasurements}
            fallback={<Spinner />}
            refetchId={clinicalRecord.clinicalData?.generalExamination?.eTag}
          >
            {result => {
              const groupedObservationsByDate =
                result.groupedObservationsByDate;

              const groupedObservationsByExamination =
                result.groupedObservationsByExamination;
              return (
                <GroupedList
                  {...getGroupsAndItems(
                    groupedObservationsByDate,
                    groupedObservationsByExamination,
                    selectedPivotKey
                  )}
                  onRenderCell={onRenderCell}
                  groupProps={{
                    onRenderHeader: (props: IGroupHeaderProps) => {
                      return renderHeader(
                        props,
                        selectedPivotKey ===
                          ObservationGroup.GroupByDateAndTypeKey
                          ? groupedObservationsByDate
                          : groupedObservationsByExamination
                      );
                    }
                  }}
                  styles={{
                    root: {
                      minHeight: 0,
                      overflow: "auto",
                      overflowX: "hidden"
                    }
                  }}
                />
              );
            }}
          </DataFetcher>
        </Stack>
      </>
    );
  });
