import { observer, Observer } from "mobx-react-lite";

import {
  ButtonsGroupChoice,
  CenteredBox,
  CenteredLargeSpinner,
  DetailsList,
  Stack
} from "@bps/fluent-ui";
import {
  BhbProviderDto,
  ProviderOnlineStatus,
  ProviderOnlineStatusText
} from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { UserOnlineBookingProfileFormHelper } from "@modules/settings/screens/users/components/user-online-booking-profile/UserOnlineBookingProfileFormHelper.ts";
import { UserProfileSection } from "@modules/settings/screens/users/components/UserBreadcrumbs.tsx";
import { BhbOnlineApptType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { BhbLocation } from "@stores/bhb/models/BhbLocation.ts";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DefaultNoResultsTile } from "@ui-components/InfiniteScrollList/DefaultNoResultsTile.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";
import {
  DetailsRowHelper,
  DetailsRowWrapper
} from "@ui-components/ShimmeredDetailsList/DetailsRowWrapper.tsx";

import { AppointmentTypeTagPicker } from "./AppointmentTypeTagPicker.tsx";
import { BhbProvidersOnlineFilter } from "./BhbProvidersCard.types.ts";
import { BhbProvidersFilter } from "./BhbProvidersFilter.tsx";

interface BhbProvidersData {
  providers: BhbProviderDto[];
  apptTypes: BhbOnlineApptType[];
  userSettings: UserSetting[];
  users: User[];
}

interface BhbProvidersFormProps {
  location: BhbLocation;
}

export const BhbProvidersForm = observer(
  ({ location }: BhbProvidersFormProps) => {
    const { bhb, core, userExperience } = useStores();
    const getItemsForProviders = (
      providerData: BhbProviderDto[],
      filters: BhbProvidersOnlineFilter | undefined
    ): BhbProviderDto[] => {
      const providers = providerData.filter(provider =>
        filterData(provider, filters)
      );

      return providers;
    };

    const filterData = (
      provider: BhbProviderDto,
      filters: BhbProvidersOnlineFilter | undefined
    ): boolean => {
      if (!filters) {
        return true;
      }

      const { search, onlineAvailability, providers, appointmentTypes } =
        filters;

      if (
        search &&
        !(
          provider.displayName.toLowerCase().includes(search.toLowerCase()) ||
          provider.firstName.toLowerCase().includes(search.toLowerCase()) ||
          provider.lastName.toLowerCase().includes(search.toLowerCase())
        )
      ) {
        return false;
      }

      if (onlineAvailability && onlineAvailability.length > 0) {
        const statusMap = {
          ShowAndBook: ProviderOnlineStatus.YES,
          ShowAndCall: ProviderOnlineStatus.CALL,
          DontShow: ProviderOnlineStatus.NO
        };

        const selectedStatus = onlineAvailability.find(
          status => provider.providerOnlineStatus === statusMap[status]
        );
        if (!selectedStatus) {
          return false;
        }
      }

      if (
        providers &&
        providers.length > 0 &&
        !providers.includes(provider.id)
      ) {
        return false;
      }

      if (
        appointmentTypes &&
        appointmentTypes.length > 0 &&
        !appointmentTypes.some(type =>
          provider.appointmentTypes.some(appType => appType.id === type)
        )
      ) {
        return false;
      }

      return true;
    };

    const buildColumns = ({
      apptTypes,
      userSettings,
      users
    }: BhbProvidersData) => {
      return [
        {
          key: "name",
          name: "Provider",
          fieldName: "name",
          minWidth: 160,
          isResizable: true,
          onRender: (item: { data: BhbProvider }) => (
            <Navigate
              to={{
                pathname: routes.settings.users.user.path({
                  id: item.data.id
                }),
                hash: UserProfileSection.OnlineBookingProfile
              }}
            >
              {item.data.displayName}
            </Navigate>
          )
        },
        {
          key: "appt-types",
          name: "Appointment types",
          fieldName: "appt-types",
          minWidth: 500,
          isResizable: true,
          onRender: (item: { data: BhbProvider; helper: DetailsRowHelper }) => (
            <AppointmentTypeTagPicker
              apptTypes={apptTypes}
              bhbProvider={item.data}
              detailsRowHelper={item.helper}
              locationId={location.id}
            />
          )
        },

        {
          key: "accept-online-bookings",
          name: "Online availability",
          fieldName: "accept-online-bookings",
          minWidth: 354,
          isResizable: true,
          onRender: (item: { data: BhbProvider; helper: DetailsRowHelper }) => {
            const setting = userSettings.find(x => x.id === item.data.id);
            const user = users.find(x => x.id === item.data.id);

            const showOnCalendarDisabled = !(
              !!setting?.showOnCalendar && user?.status === UserStatus.Active
            );

            const providerOnlineStatus = [
              {
                key: ProviderOnlineStatus.YES,
                text: ProviderOnlineStatusText.ShowAndBook,
                disabled: showOnCalendarDisabled
              },
              {
                key: ProviderOnlineStatus.CALL,
                text: ProviderOnlineStatusText.ShowAndCall
              },
              {
                key: ProviderOnlineStatus.NO,
                text: ProviderOnlineStatusText.DontShow
              }
            ];

            return (
              <Observer>
                {() => (
                  <ButtonsGroupChoice<ProviderOnlineStatus | undefined>
                    key={item.data.id}
                    equalWidth
                    options={providerOnlineStatus}
                    value={item.data.providerOnlineStatus}
                    onChange={(
                      providerOnlineStatus: ProviderOnlineStatus | undefined
                    ) => {
                      const updateProvider = {
                        ...item.data.dto,
                        providerOnlineStatus
                      };
                      item.helper.setPromise(
                        bhb.updateProviderForLocation(
                          updateProvider,
                          location.id
                        )
                      );
                    }}
                  />
                )}
              </Observer>
            );
          }
        }
      ];
    };

    const fetchProvidersAndUserSettings = async () => {
      const providersUnsorted =
        (await bhb.getProvidersForLocation(location.id)) ?? [];

      const userSettings = providersUnsorted.length
        ? await userExperience.getUserSettingsByUserIds(
            providersUnsorted.map(x => x.id)
          )
        : [];

      return {
        userSettings,
        providers: Array.from(providersUnsorted).sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        )
      };
    };

    const fetch = async () => {
      const [apptTypesForLocation, { providers, userSettings }, users] =
        await Promise.all([
          bhb.getAppointmentTypesForLocation(),
          fetchProvidersAndUserSettings(),
          core.fetchUsers({
            showOnCalendar: true,
            statusCodes: [UserStatus.Active],
            businessRoleClasses: [BusinessRoleClasses.Provider]
          })
        ]);

      const onlineAppointmentTypes = apptTypesForLocation
        ? UserOnlineBookingProfileFormHelper.reduceToOnlineApptTypes(
            apptTypesForLocation
          )
        : [];

      return {
        providers,
        apptTypes: onlineAppointmentTypes,
        userSettings,
        users
      };
    };

    return (
      <DataFetcher<BhbProvidersData>
        fetch={fetch}
        fallback={<CenteredLargeSpinner />}
      >
        {data => (
          <BhbProvidersFilter
            providers={data.providers}
            apptTypes={data.apptTypes}
          >
            {({ values }) => {
              const providers = Array.from(
                getItemsForProviders(data.providers, values)
              );
              if (providers.length > 0) {
                return (
                  <Stack
                    styles={{ root: { maxHeight: 600, overflow: "auto" } }}
                  >
                    <DetailsList
                      stickyHeader
                      items={providers}
                      onRenderRow={props => <DetailsRowWrapper props={props} />}
                      columns={buildColumns(data)}
                    />
                  </Stack>
                );
              } else {
                return (
                  <CenteredBox>
                    <DefaultNoResultsTile defaultText="No records found" />
                  </CenteredBox>
                );
              }
            }}
          </BhbProvidersFilter>
        )}
      </DataFetcher>
    );
  }
);
