import {
  ContactStatus,
  ContactType,
  CscDto,
  DvaDto,
  EntitlementDto,
  ExternalProviderDto,
  HealthFundDto,
  InternalProviderDto,
  MedicareDto,
  PatientDto,
  RelationshipDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { AddressFieldValue } from "@shared-types/practice/address-field-value.type.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { RelationshipFieldValue } from "./relationships/utils.ts";

export interface PatientEditFormValues
  extends Partial<
    Pick<
      PatientDto,
      | "id"
      | "type"
      | "commsRelationshipId"
      | "isCommsSentToRelative"
      | "occupation"
      | "sex"
      | "gender"
      | "profilePictureUrl"
      | "pronounSubjective"
      | "pronounObjective"
      | "pronounPossessive"
    >
  > {
  ethnicities: string[];
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
  status?: ContactStatus;
  relationshipStatus?: string;
  communications: CommunicationFieldValue[];
  addresses: AddressFieldValue[];
  medicare?: Partial<MedicareDto>;
  healthInsurance?: HealthInsuranceFields;
  dva?: Partial<DvaDto>;
  entitlements: EntitlementDto[];
  nhi?: string;
  birthDate?: Date;
  dateOfDeath?: Date;
  externalProvider: ExternalProviderFieldValue[];
  internalProvider: InternalProviderFieldValue[];
  relationships: RelationshipFieldValue[];
  accountHolders: RelationshipFieldValue[];
  employers: EmployerFieldValue[];
  interpreterNeeded?: boolean;
  interpreterLanguage?: string;
  csc?: CscFields;
  appointmentReminderType?: string;
  appointmentReminderValue?: number;
  appointmentConfirmationType?: string;
  appointmentConfirmationValue?: number;
  invoiceCommunicationType?: string;
  invoiceCommunicationValue?: number;
  formNotifyType?: string;
  formNotifyValue?: number;
  formNotifyValueChanged?: boolean;
  adminReminderType?: string;
  adminReminderValue?: number;
  smsForm?: number;
  emailForm?: number;
}

export type HealthInsuranceFields = Partial<Omit<HealthFundDto, "expiry">> & {
  expiry?: Date;
};

type CscFields = Partial<Omit<CscDto, "startDate" | "expiry">> & {
  startDate?: Date;
  expiry?: Date;
};

export interface ContactEditFormValues
  extends Partial<
    Pick<
      Contact,
      | "id"
      | "firstName"
      | "lastName"
      | "title"
      | "profilePictureUrl"
      | "pronounSubjective"
      | "pronounObjective"
      | "pronounPossessive"
      | "gender"
    >
  > {
  type: ContactType;
  communications: CommunicationFieldValue[];
  addresses: AddressFieldValue[];
  relationships: RelationshipFieldValue[];
  disciplines?: string[];
  status?: ContactStatus;
  employers: EmployerFieldValue[];
  appointmentReminderType?: string;
  appointmentReminderValue?: number;
  appointmentConfirmationType?: string;
  appointmentConfirmationValue?: number;
  invoiceCommunicationType?: string;
  invoiceCommunicationValue?: number;
  formNotifyType?: string;
  formNotifyValue?: number;
  formNotifyValueChanged?: number;
  adminReminderType?: string;
  adminReminderValue?: number;
  isHealthProvider: boolean;
}

export interface ExternalProviderFieldValue extends ExternalProviderDto {
  id: string;
  isNew?: boolean;
}

export interface InternalProviderFieldValue
  extends Partial<InternalProviderDto> {
  id: string;
  isNew?: boolean;
}

export type EmployerFieldValue = RelationshipDto & {
  animationId: string;
};

export const patientFormNameOf = nameOfFactory<PatientEditFormValues>();
export const contactFormNameOf = nameOfFactory<ContactEditFormValues>();
