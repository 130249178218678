import { getIn } from "final-form";
import { useForm } from "react-final-form";

import { Country } from "@libs/enums/country.enum.ts";
import { OutboundCommChannel } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";

import { getDropdownValues } from "./CommsPreferenceItem.tsx";
import { EditContactMethods } from "./EditContactMethods.tsx";
import { EditPreferencesConsents } from "./EditPreferencesConsents.tsx";
import {
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

interface CommsAndPreferencesProps {
  country: Country;
}

export const EditCommsAndPreferences: React.FC<CommsAndPreferencesProps> = ({
  country
}) => {
  const { change, batch } = useForm<PatientEditFormValues>();
  const onRemoveComms = (index: number, values: PatientEditFormValues) => {
    batch(() => {
      const doomed = values.communications[index];
      if (doomed.type === CommunicationType.Mobile) {
        const allMobile = values.communications.filter(
          x => x.type === CommunicationType.Mobile
        );

        const mobileIndex = allMobile.findIndex(x => x.value === doomed.value);
        if (mobileIndex >= 0 && typeof values.smsForm === "number") {
          if (mobileIndex === values.smsForm) {
            change(patientFormNameOf("smsForm"), undefined);
          } else if (mobileIndex < values.smsForm) {
            change(patientFormNameOf("smsForm"), values.smsForm - 1);
          }
        }
      } else if (doomed.type === CommunicationType.Email) {
        const allEmail = values.communications.filter(
          x => x.type === CommunicationType.Email
        );

        const emailIndex = allEmail.findIndex(x => x.value === doomed.value);
        if (typeof values.emailForm === "number" && emailIndex >= 0) {
          if (emailIndex === values.emailForm) {
            change(patientFormNameOf("emailForm"), undefined);
          } else if (emailIndex < values.emailForm) {
            change(patientFormNameOf("emailForm"), values.emailForm - 1);
          }
        }
      }

      const toCheck: {
        type?: string;
        valueField: keyof PatientEditFormValues;
      }[] = [
        {
          type: values.appointmentConfirmationType,
          valueField: "appointmentConfirmationValue"
        },
        {
          type: values.appointmentReminderType,
          valueField: "appointmentReminderValue"
        },
        {
          type: values.invoiceCommunicationType,
          valueField: "invoiceCommunicationValue"
        },
        {
          type: values.formNotifyType,
          valueField: "formNotifyValue"
        }
      ];

      toCheck.forEach(({ type, valueField }) => {
        const value = getIn(values, valueField);
        if (typeof value === "number") {
          if (
            doomed.type === CommunicationType.Email &&
            type === OutboundCommChannel.Email
          ) {
            const allEmail = values.communications.filter(
              x => x.type === CommunicationType.Email
            );

            const emailIndex = allEmail.findIndex(
              x => x.value === doomed.value
            );
            if (emailIndex === value) {
              change(valueField, undefined);
            } else if (emailIndex < value) {
              change(valueField, value - 1);
            }
          } else if (
            doomed.type === CommunicationType.Mobile &&
            type === OutboundCommChannel.Sms
          ) {
            const allMobile = values.communications.filter(
              x => x.type === CommunicationType.Mobile
            );

            const mobileIndex = allMobile.findIndex(
              x => x.value === doomed.value
            );
            if (mobileIndex === value) {
              change(valueField, undefined);
            } else if (mobileIndex < value) {
              change(valueField, value - 1);
            }
          }
        }
      });
    });
  };

  const onEmailFormCheckboxChanged = (values?: PatientEditFormValues) => {
    if (values && typeof values.emailForm === "number") {
      const emails = getDropdownValues(values, OutboundCommChannel.Email);
      const checked = values.emailForm < emails.length && values.emailForm >= 0;
      if (checked) {
        change(patientFormNameOf("formNotifyType"), OutboundCommChannel.Email);
        change(patientFormNameOf("smsForm"), undefined);
        change(patientFormNameOf("formNotifyValue"), values.emailForm);
      }
    }
  };

  const onSmsFormCheckboxChanged = (values?: PatientEditFormValues) => {
    if (values && typeof values.smsForm === "number") {
      // SMS
      const sms = getDropdownValues(values, OutboundCommChannel.Sms);
      const checked = values.smsForm < sms.length && values.smsForm >= 0;
      if (checked) {
        change(patientFormNameOf("formNotifyType"), OutboundCommChannel.Sms);
        change(patientFormNameOf("formNotifyValue"), values.smsForm);
        change(patientFormNameOf("emailForm"), undefined);
      }
    }
  };

  return (
    <>
      <EditContactMethods
        country={country}
        onRemoveCommunication={onRemoveComms}
      />
      <EditPreferencesConsents />
      <FieldSpy<boolean, PatientEditFormValues>
        name={patientFormNameOf("emailForm")}
        onChange={(_, values) => onEmailFormCheckboxChanged(values)}
      />
      <FieldSpy<boolean, PatientEditFormValues>
        name={patientFormNameOf("smsForm")}
        onChange={(_, values) => onSmsFormCheckboxChanged(values)}
      />
    </>
  );
};
