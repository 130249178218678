import { observer } from "mobx-react-lite";
import { parse } from "query-string";
import { useCallback } from "react";

import {
  confirm,
  dataAttribute,
  DataAttributes,
  IconButton,
  NoDataTile,
  RESET_CELLS_PADDING_CLASSNAME,
  ScrollablePane,
  Text,
  TooltipHost
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import { InvoiceItemDto } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { currencyFormat } from "@libs/utils/currency.utils.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ContactFetcher } from "@ui-components/ContactFetcher.tsx";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { DraftItemsFilterQuery } from "./DraftItemsFilterBar.tsx";

export const DraftItemsList = observer(() => {
  const { billing, routing, userExperience, notification } = useStores();
  const nameOf = nameOfFactory<InvoiceItemDto>();

  const { localisedConfig } = userExperience;

  const patientLabel = localisedConfig("patientDisplay", {
    capitalizeFirst: true
  });

  const onSearch = useCallback(
    async (query?: PagingOptions): Promise<QueryResult<InvoiceItemDto>> => {
      const searchQuery = routing.location.search;
      const filter: DraftItemsFilterQuery = searchQuery
        ? parse(searchQuery, {
            parseBooleans: true
          })
        : {};
      return await billing.fetchInvoiceItems({
        ...query,
        ...filter,
        draftOnly: true
      });
    },
    [billing, routing.location.search]
  );

  const columns = [
    {
      name: "Service date",
      key: nameOf("serviceDate"),
      minWidth: 99,
      maxWidth: 99,
      onRender: (item: InvoiceItemDto) => {
        if (item.serviceDate) {
          return DateTime.fromISO(item.serviceDate).toDayDefaultFormat();
        }
        return null;
      }
    },
    {
      name: "Bill to",
      key: nameOf("accountId"),
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: InvoiceItemDto) =>
        item.accountId && (
          <ContactFetcher contactId={item.accountId}>
            {contact => {
              return (
                <Navigate
                  to={{
                    pathname: routes.accounts.account.path({
                      id: contact.id
                    })
                  }}
                >
                  {contact.name}
                </Navigate>
              );
            }}
          </ContactFetcher>
        )
    },
    {
      name: patientLabel,
      key: nameOf("patient"),
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: InvoiceItemDto) =>
        item.patient && (
          <Navigate
            to={{
              pathname: routes.accounts.account.path({
                id: item.patientId
              })
            }}
            state={{
              accountsFilter: routing.location.search
            }}
          >
            {item.patient.firstName} {item.patient.lastName}
          </Navigate>
        )
    },
    {
      name: "Value ($)",
      key: nameOf("amount"),
      minWidth: 72,
      maxWidth: 72,
      styles: { cellTitle: { justifyContent: "flex-end" } },
      onRender: (item: InvoiceItemDto) => {
        if (item.amount && !isNaN(item.amount)) {
          return (
            <Text
              styles={{
                root: {
                  marginLeft: "auto"
                }
              }}
            >
              {currencyFormat(item.amount, { currency: "" })}
            </Text>
          );
        }
        return null;
      }
    },
    {
      name: "Draft items",
      key: nameOf("code"),
      minWidth: 120,
      maxWidth: 300,
      onRender: (item: InvoiceItemDto) => {
        return (
          <DataFetcher
            fetch={({ billing }) =>
              billing.getService(item.serviceId, { ignoreCache: false })
            }
          >
            {service => (
              <>
                <Text bold>
                  {service?.name ? `${service?.code}: ` : service?.code}
                </Text>
                <Text nowrap>{service?.name}</Text>
              </>
            )}
          </DataFetcher>
        );
      }
    },
    {
      name: "Claim",
      key: nameOf("claimId"),
      minWidth: 80,
      maxWidth: 100,
      onRender: (item: InvoiceItemDto) =>
        item.accScheduleItem?.claimNumber &&
        item.accScheduleItem?.claimId && (
          <Navigate
            to={routes.claims.management.edit.path({
              id: item.accScheduleItem?.claimId
            })}
          >
            {item.accScheduleItem.claimNumber}
          </Navigate>
        )
    },
    {
      name: "Provider",
      key: nameOf("userId"),
      minWidth: 100,
      maxWidth: 360,
      onRender: (item: InvoiceItemDto) =>
        item.user && (
          <Text>
            {item.user.title || ""} {item.user.firstName} {item.user.lastName}
          </Text>
        )
    },
    {
      name: "",
      className: RESET_CELLS_PADDING_CLASSNAME,
      key: "delete",
      minWidth: 30,
      maxWidth: 30,
      onRender: (item: InvoiceItemDto) => (
        <TooltipHost content="Delete">
          <IconButton
            {...dataAttribute(
              DataAttributes.Element,
              "draft-items-delete-button"
            )}
            iconProps={{ iconName: "Delete" }}
            onClick={() => {
              deleteDraftItem(item.id);
            }}
            styles={{
              root: { height: "36px" },
              flexContainer: { height: "36px" }
            }}
          />
        </TooltipHost>
      )
    }
  ];

  const deleteDraftItem = async (id: string) => {
    const isConfirmed = await confirm({
      maxWidth: 560,
      cancelButtonProps: {
        text: "Cancel"
      },
      confirmButtonProps: {
        text: "Delete draft"
      },
      dialogContentProps: {
        title: "Confirm draft deletion",
        subText: "Do you want to delete this draft item permanently?"
      }
    });

    if (isConfirmed) {
      try {
        await billing.deleteBulkDraftItems([id]);
        notification.success(notificationMessages.draftItemDeleted);
      } catch (e) {
        notification.error(e.message);
      }
    }
  };

  return (
    <div style={{ position: "relative", flexGrow: 1 }}>
      <ScrollablePane>
        <InfiniteScrollList
          refreshKey={billing.ui.lastDraftInvoiceItemChange}
          setKey="billing-list"
          getItems={onSearch}
          displayDefaultCheckBox
          checkboxAutomationAttribute="draft-invoice-item-checkbox"
          onRenderNoResults={() => (
            <NoDataTile
              textProps={{
                text: "No draft items"
              }}
              linkProps={{
                hidden: true
              }}
              showBoxShadow={false}
            />
          )}
          detailsListStyles={{
            root: {
              ".ms-DetailsRow-cell": { alignSelf: "center" }
            }
          }}
          columns={columns}
        />
      </ScrollablePane>
    </div>
  );
});
