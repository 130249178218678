import { useContext } from "react";

import { Stack } from "@bps/fluent-ui";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";

import { StatementItemsContext } from "../context/StatementItemsContext.ts";

export const StatementValueFilter: React.FC = () => {
  const { nameOf } = useContext(StatementItemsContext);
  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <SpinNumberInputField
        name={nameOf("owingOver")}
        label="Owing over"
        prefix="$"
        step={0.01}
        precision={2}
      />
      <SpinNumberInputField
        name={nameOf("creditOver")}
        label="Credit over"
        prefix="$"
        step={0.01}
        precision={2}
      />
    </Stack>
  );
};
