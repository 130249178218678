import { observer } from "mobx-react-lite";
import { useField, useForm } from "react-final-form";

import {
  dataAttribute,
  DataAttributes,
  Heading,
  IDropdownOption,
  MessageBar,
  Stack
} from "@bps/fluent-ui";
import {
  OPTOUT,
  OutboundCommChannel,
  OutboundCommChannelMapping
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { CommunicationFieldValue } from "@shared-types/practice/communication-field-value.type.ts";

import { Labels } from "../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../shared-components/types/patient-card-ids.enum.ts";
import {
  CommsPreferenceItem,
  CommsPreferenceItemProps
} from "./CommsPreferenceItem.tsx";
import { InterpreterNeededField } from "./InterpreterNeededField.tsx";
import {
  contactFormNameOf,
  PatientEditFormValues,
  patientFormNameOf
} from "./PatientEditFormValues.tsx";

const optOutOption = { key: OPTOUT, text: OPTOUT };

export const EditPreferencesConsents: React.FC = observer(() => {
  const { change, batch } = useForm<PatientEditFormValues>();
  const { Sms, Email } = OutboundCommChannel;

  const isSendToAndPreferenceMismatched = (
    commChannel: OutboundCommChannel,
    input: {
      prefType: OutboundCommChannel;
      prefValue?: number;
      sendTo?: number;
    }
  ) => {
    const { prefType, sendTo, prefValue } = input;

    return prefType === commChannel && prefValue !== sendTo;
  };

  const onFormPreferenceValueSelected: (
    values?: PatientEditFormValues
  ) => void = values => {
    if (values) {
      const { smsForm, emailForm, formNotifyType, formNotifyValue } = values;

      if (typeof formNotifyValue !== "number") return;

      batch(() => {
        if (
          isSendToAndPreferenceMismatched(Sms, {
            sendTo: smsForm,
            prefType: formNotifyType as OutboundCommChannel,
            prefValue: formNotifyValue
          })
        ) {
          change(patientFormNameOf("smsForm"), undefined);
        }
        if (
          isSendToAndPreferenceMismatched(Email, {
            sendTo: emailForm,
            prefType: formNotifyType as OutboundCommChannel,
            prefValue: formNotifyValue
          })
        ) {
          change(patientFormNameOf("emailForm"), undefined);
        }
      });
    }
  };

  const onFormPreferenceTypeSelected: (
    values?: PatientEditFormValues
  ) => void = values => {
    batch(() => {
      if (values) {
        const {
          smsForm,
          emailForm,
          formNotifyType,
          formNotifyValue,
          communications
        } = values;

        if (
          smsForm &&
          isSendToAndPreferenceMismatched(Sms, {
            sendTo: smsForm,
            prefType: formNotifyType as OutboundCommChannel,
            prefValue: formNotifyValue
          })
        ) {
          change(patientFormNameOf("smsForm"), undefined);
        }
        if (
          emailForm &&
          isSendToAndPreferenceMismatched(Email, {
            sendTo: emailForm,
            prefType: formNotifyType as OutboundCommChannel,
            prefValue: formNotifyValue
          })
        ) {
          change(patientFormNameOf("emailForm"), undefined);
        }
        if (
          !formNotifyValue ||
          (formNotifyValue !== smsForm && formNotifyValue !== emailForm)
        ) {
          const data = formNotifyType
            ? communications.filter(
                i => i.type === OutboundCommChannelMapping[formNotifyType]
              )
            : [];
          if (data.length === 1) {
            change(patientFormNameOf("formNotifyValue"), 0);
          } else {
            change(patientFormNameOf("formNotifyValue"), undefined);
          }
        }
      }
    });
  };

  const {
    input: { value: communications }
  } = useField<CommunicationFieldValue[]>(patientFormNameOf("communications"), {
    subscription: { value: true }
  });

  const {
    input: { value: interpreterNeeded }
  } = useField<boolean | undefined>(patientFormNameOf("interpreterNeeded"), {
    subscription: { value: true }
  });

  const {
    input: { value: isHealthProvider }
  } = useField<boolean | undefined>(contactFormNameOf("isHealthProvider"), {
    subscription: { value: true }
  });

  const isOptionDisabled = (channelCode: string) => {
    if (channelCode === OutboundCommChannel.Sms) {
      return !communications.some(
        communication =>
          communication.type === CommunicationType.Mobile && communication.value
      );
    }

    if (channelCode === OutboundCommChannel.Email) {
      return !communications.some(
        communication =>
          communication.type === CommunicationType.Email && communication.value
      );
    }

    return false;
  };

  const emailOption: IDropdownOption = {
    key: OutboundCommChannel.Email,
    text: OutboundCommChannel.Email,
    disabled: isOptionDisabled(OutboundCommChannel.Email)
  };

  const smsOption: IDropdownOption = {
    key: OutboundCommChannel.Sms,
    text: OutboundCommChannel.Sms,
    disabled: isOptionDisabled(OutboundCommChannel.Sms)
  };

  const preferenceItems: CommsPreferenceItemProps[] = [
    {
      key: "billing",
      label: "Billing correspondence",
      automationName: "invoice-communication",
      typeFieldName: patientFormNameOf("invoiceCommunicationType"),
      typeOptions: [emailOption, optOutOption],
      valueFieldName: patientFormNameOf("invoiceCommunicationValue")
    },
    {
      key: "forms",
      label: "Forms",
      automationName: "form-communication",
      typeFieldName: patientFormNameOf("formNotifyType"),
      typeOptions: [emailOption, optOutOption],
      valueFieldName: patientFormNameOf("formNotifyValue"),
      onValueSelected: values => onFormPreferenceValueSelected(values),
      onTypeSelected: values => onFormPreferenceTypeSelected(values)
    }
  ];

  if (!isHealthProvider) {
    preferenceItems.unshift(
      {
        key: "reminders",
        label: "Reminders",
        automationName: "appointment-reminder",
        typeFieldName: patientFormNameOf("appointmentReminderType"),
        typeOptions: [smsOption, optOutOption],
        valueFieldName: patientFormNameOf("appointmentReminderValue")
      },
      {
        key: "confirmations",
        label: "Confirmations",
        permission: Permission.ApptConfirmationsAllowed,
        automationName: "appointment-confirmation",
        typeFieldName: patientFormNameOf("appointmentConfirmationType"),
        typeOptions: [emailOption, optOutOption],
        valueFieldName: patientFormNameOf("appointmentConfirmationValue")
      },
      {
        key: "adminreminders",
        label: "Administration Reminders",
        permission: Permission.ApptConfirmationsAllowed,
        automationName: "administration-reminders",
        typeFieldName: patientFormNameOf("adminReminderType"),
        typeOptions: [emailOption, smsOption, optOutOption],
        valueFieldName: patientFormNameOf("adminReminderValue")
      }
    );
  }

  const someOptionsDisabled = smsOption.disabled || emailOption.disabled;

  return (
    <Stack tokens={{ childrenGap: 8 }} id={`${PatientCardIds.prefCons}-edit`}>
      <Heading variant="section-heading-light">
        {Labels.communicationPreferences}
      </Heading>
      {someOptionsDisabled && !isHealthProvider && (
        <MessageBar
          {...dataAttribute(
            DataAttributes.Element,
            "some-options-disabled-message-bar"
          )}
        >
          Some preferences can't be defined due to missing contact methods.
          Please complete the contact methods to set up the preferred ways of
          communications.
        </MessageBar>
      )}
      {preferenceItems.map((x: CommsPreferenceItemProps) => (
        <CommsPreferenceItem {...x} key={x.key} />
      ))}

      <InterpreterNeededField interpreterNeeded={!!interpreterNeeded} />
    </Stack>
  );
});
