import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { Field, useForm } from "react-final-form";

import { ProfilePhotoPicker, Stack, useResizeImage } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";

import { onlineBookingProfileNameOf } from "./UserOnlineBookingProfileForm.tsx";

interface EditBhbProviderProfilePhotoPickerProps {
  id: string;
  firstName: string | undefined;
  lastName: string | undefined;
}

const bhbDefaultImgDimensions = 1024 * 1024;

export const BhbProviderProfilePhotoPicker: FunctionComponent<EditBhbProviderProfilePhotoPickerProps> =
  observer(({ id, firstName, lastName }) => {
    const root = useStores();
    const { bhb, core } = root;

    const form = useForm();

    const {
      handleImageUpload,
      uploadingError,
      setUploadingError,
      setLoading,
      loading
    } = useResizeImage();

    const uploadPhoto = async (files: File[], providerId: string) => {
      setUploadingError(undefined);

      if (!files.length || files.length === 0) return;

      let file = files[0];
      if (file.size > bhbDefaultImgDimensions) {
        const resizedImage = await handleImageUpload(file);
        if (resizedImage) {
          file = resizedImage;
        }
      }

      try {
        const link = await bhb.uploadPhoto(core.locationId, providerId, file);
        form.change(onlineBookingProfileNameOf("photoUrl"), link);
      } catch (e) {
        setUploadingError(e.message);
      } finally {
        setLoading(false);
      }

      return;
    };
    return (
      <Stack
        tokens={{ childrenGap: 8 }}
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Field name="photoUrl" errorMessage={uploadingError}>
          {({ input: { value } }) => (
            <ProfilePhotoPicker
              hasError={!!uploadingError}
              imageUrl={value}
              loading={loading}
              onDropAccepted={(files: File[]) => uploadPhoto(files, id)}
              onPictureRemoved={function (): void {
                form.change(onlineBookingProfileNameOf("photoUrl"), undefined);
              }}
              imageInitials={`${firstName?.charAt(0) ?? ""}${
                lastName?.charAt(0) ?? ""
              }`}
              styles={{ root: { width: 200 } }}
              addPhotoLinkText="Update profile photo"
            />
          )}
        </Field>
      </Stack>
    );
  });
