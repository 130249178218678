import { Stack } from "@bps/fluent-ui";

import { BhbConfigurationSettingsCard } from "./bhb-configuration-card/BhbConfigurationSettingsCard.tsx";
import { BhbOnlineSettingsCard } from "./online-booking-settings-card/BhbOnlineSettingsCard.tsx";

const BhbOnlineSettingsScreen: React.FC = () => {
  return (
    <Stack tokens={{ childrenGap: 24 }}>
      <BhbConfigurationSettingsCard />
      <BhbOnlineSettingsCard />
    </Stack>
  );
};

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default BhbOnlineSettingsScreen;
