import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import {
  CommandBarButton,
  TextBadge,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { TIME_FORMATS } from "@bps/utils";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  appointmentFormNameOf,
  GroupApptSlot
} from "../AppointmentForm.types.ts";

interface AppointmentSlotProps {
  slot: GroupApptSlot;
  providerId: string;
  pickerStartDate: Date;
  sliderStartDate: Date;
  isExpanded: boolean;
}

export const AppointmentSlot: FunctionComponent<AppointmentSlotProps> = ({
  slot,
  providerId,
  pickerStartDate,
  sliderStartDate,
  isExpanded
}) => {
  const theme = useTheme();

  const { booking } = useStores();

  const form = useForm<AppointmentFormValues>();

  const updateTime = (slot: GroupApptSlot) => async () => {
    const startDate = isExpanded ? sliderStartDate : pickerStartDate;

    const startTime = slot.startDateTime.toFormat(
      TIME_FORMATS.TIME_FORMAT_WITH_SECONDS
    );

    const values = form.getState().values;
    const attenddeeIds = slot.attendees.map(x => x.attendeeId);

    if (values.patientId) {
      attenddeeIds.push(values.patientId);
    }

    form.batch(() => {
      form.change(appointmentFormNameOf("groupAttendeeIds"), attenddeeIds);
      form.change(appointmentFormNameOf("startTime"), startTime);
      form.change(appointmentFormNameOf("startDate"), startDate);
      form.change(appointmentFormNameOf("providerId"), providerId);
      form.change(
        appointmentFormNameOf("selectedApptId"),
        slot.calendarEventId
      );
      form.change(appointmentFormNameOf("groupAttendees"), slot.attendees);
      form.change(appointmentFormNameOf("orgUnitId"), slot.orgUnitId);
    });

    closePanel(slot.startTime);
  };

  const closePanel = (value: string | undefined) => {
    if (
      value &&
      booking.ui.currentAppointment?.secondColumnContent ===
        SecondColumnContent.nextGroupAppt &&
      providerId &&
      pickerStartDate
    ) {
      booking.ui.setSecondColumnContent(undefined);
    }
  };

  const timeSlotToRender = slot.startDateTime.toFormat(
    TIME_FORMATS.DEFAULT_TIME_FORMAT
  );

  return (
    <CommandBarButton
      onClick={updateTime(slot)}
      styles={{
        root: {
          padding: "0",
          backgroundColor: "inherit"
        },
        label: {
          margin: 0
        }
      }}
      text={timeSlotToRender}
      toggle
    >
      <TextBadge
        badgeSize={TextBadgeSize.small}
        customColors={{
          backgroundColor: theme.palette.neutralLighter
        }}
        styles={{ root: { margin: "4px" } }}
      >
        {`${slot.remainingSpaces} spaces`}
      </TextBadge>
    </CommandBarButton>
  );
};
