import { FunctionComponent } from "react";
import { useForm } from "react-final-form";

import {
  FontIcon,
  FontSizes,
  PersonaCoin,
  PersonaSize,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { routes } from "@libs/routing/routes.ts";
import {
  getFileFromUrl,
  getInitialsText
} from "@modules/settings/screens/practice/utils.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { Navigate } from "@ui-components/navigation/Navigate.tsx";

import { bhbOnlineSettingsFormNameOf } from "./BhbOnlineSettingsCard.types.ts";

// possible bug here where if the org unit profile picture URL is changed, the form
//  will still save the old URL but will to display to the user the image from the new URL

export const ApplyPracticeLogo: FunctionComponent = () => {
  const { bhb, core } = useStores();
  const form = useForm();
  const getPracticeLogoLink = async (practiceLogoUrl?: string) => {
    const file = practiceLogoUrl
      ? await getFileFromUrl("practiceLogo", practiceLogoUrl)
      : undefined;

    return file && (await bhb.uploadLogo(file));
  };

  return (
    <DataFetcher<string | undefined>
      fetch={async ({ practice }) => {
        const orgUnit = await practice.getOrgUnit(
          core.location.parentOrgUnit!.id,
          {
            ignoreCache: true
          }
        );
        return orgUnit?.profilePictureUrl;
      }}
    >
      {practiceLogoUrl => (
        <Stack horizontal>
          <CheckboxField
            label="Apply practice logo online"
            name={bhbOnlineSettingsFormNameOf("hasLogoUrl")}
            disabled={!practiceLogoUrl}
          />
          <FieldSpy
            name={bhbOnlineSettingsFormNameOf("hasLogoUrl")}
            onChange={async value => {
              if (value) {
                const logoUrl = await getPracticeLogoLink(practiceLogoUrl);
                form.change("logoUrl", logoUrl);
              } else {
                form.change("logoUrl", undefined);
              }
            }}
          />

          <TooltipHost
            closeDelay={300}
            content={
              <Stack>
                <PersonaCoin
                  size={PersonaSize.size100}
                  imageInitials={getInitialsText(core.location.name)}
                  imageUrl={practiceLogoUrl}
                  styles={{
                    initials: { borderRadius: 0 },
                    image: { borderRadius: 0, img: { objectFit: "contain" } }
                  }}
                />
                <Navigate
                  to={{
                    pathname: routes.settings.practices.basePath.path({})
                  }}
                  styles={{
                    root: {
                      textAlign: "center",
                      marginTop: 8,
                      fontSize: FontSizes.size14
                    }
                  }}
                >
                  Edit
                </Navigate>
              </Stack>
            }
          >
            <FontIcon
              iconName="Info"
              styles={{
                root: {
                  fontSize: FontSizes.size16,
                  marginLeft: 8,
                  verticalAlign: "middle"
                }
              }}
            />
          </TooltipHost>
        </Stack>
      )}
    </DataFetcher>
  );
};
