import { maxLength } from "@libs/validation/fieldValidators.ts";
import { Validator } from "@libs/validation/Validator.ts";

import {
  bhbConfigurationFormNameOf,
  BhbConfigurationFormValues
} from "./BhbConfigurationSettingsForm.types.ts";

export class BhbConfigurationFormValidator extends Validator<BhbConfigurationFormValues> {
  constructor() {
    super();
    this.forField(bhbConfigurationFormNameOf("parkingAccess"), [
      maxLength(500)
    ]);
    this.forField(bhbConfigurationFormNameOf("disabilityAccess"), [
      maxLength(500)
    ]);
  }
}
