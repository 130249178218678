import { observer } from "mobx-react-lite";
import { FunctionComponent, useContext } from "react";

import {
  MessageBar,
  MessageBarType,
  Stack,
  StandardRangesOptionsKeys
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StatementItemsContext } from "@modules/billing/screens/statement/context/StatementItemsContext.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DatesRangePickerField } from "@ui-components/form/DatesRangePickerField/DatesRangePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { StatementValueFilter } from "./StatementValueFilter.tsx";

export const StatementGenerateModalFields: FunctionComponent = observer(() => {
  const { nameOf } = useContext(StatementItemsContext);

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <DataFetcher fetch={({ billing }) => billing.getLastDateStatement()}>
        {res => {
          const dateString =
            res === "0" || res === undefined
              ? undefined
              : DateTime.fromISO(res);
          if (!dateString) return null;

          return (
            <MessageBar messageBarType={MessageBarType.info}>
              Last statement run was {dateString.toDayDefaultFormat()}
            </MessageBar>
          );
        }}
      </DataFetcher>

      <DatesRangePickerField
        styles={{ root: { width: "50%" }, fieldGroup: { width: 200 } }}
        placeholder="Date(s)"
        required
        standardRanges={[
          StandardRangesOptionsKeys.previousMonth,
          StandardRangesOptionsKeys.thisMonth
        ]}
        label="Date range"
        endDateName={nameOf("endDate")}
        startDateName={nameOf("startDate")}
        endDateProps={{ maxDate: DateTime.jsDateNow() }}
        closeCalloutOnSelection
        renderCloseBtn
        hidePasteStartButton
      />
      <YesNoToggleField
        label="Filter by account value"
        name={nameOf("valueFilter")}
      />
      <FieldCondition
        when={nameOf("valueFilter")}
        is={(value: boolean) => !!value}
      >
        <StatementValueFilter />
      </FieldCondition>
    </Stack>
  );
});
