import { observer } from "mobx-react-lite";
import { useField } from "react-final-form";

import { Heading, Stack } from "@bps/fluent-ui";
import { ShowAvailabilityEnum } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { OptionsSelectField } from "@ui-components/form/selects/OptionsSelectField.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";

import {
  bhbOnlineSettingsFormNameOf,
  BhbOnlineSettingsFormValues,
  buttonGroupOptons
} from "./BhbOnlineSettingsCard.types.ts";

const showAvailabilityOptions = Object.values(ShowAvailabilityEnum)
  .filter(y => !isNaN(Number(y)) && y)
  .map(value => {
    return { key: String(value), text: `${value}` };
  });

export const BhbOnlineSettingsTimeframesSection = observer(() => {
  const {
    input: { value: limitMaximumAppointmentsPerDay }
  } = useField<BhbOnlineSettingsFormValues["limitMaximumAppointmentsPerDay"]>(
    bhbOnlineSettingsFormNameOf("limitMaximumAppointmentsPerDay")
  );

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Heading variant="section-heading-light" labelPaddings>
        Booking availability timeframes & limits
      </Heading>

      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Heading labelPaddings>Max</Heading>
        <OptionsSelectField
          name={bhbOnlineSettingsFormNameOf("showAvailability")}
          options={showAvailabilityOptions}
          styles={{ root: { width: 80 } }}
          hideClearButton
          hideSearchOption
        />
        <Heading labelPaddings>weeks — Min</Heading>
        <SpinNumberInputField
          name={bhbOnlineSettingsFormNameOf("minimumTimeToBookValue")}
          parse={value => Number(value)}
          styles={{ root: { width: 80 } }}
          min={1}
        />
        <ButtonsGroupSingleChoiceField
          options={buttonGroupOptons}
          name={bhbOnlineSettingsFormNameOf("minimumTimeToBookUnit")}
        />
        <Heading labelPaddings>before the appointment</Heading>
      </Stack>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
        styles={{ root: { minHeight: 32 } }}
      >
        <Heading labelPaddings>
          Daily appointments number per patient is
        </Heading>
        <ToggleField
          name={bhbOnlineSettingsFormNameOf("limitMaximumAppointmentsPerDay")}
          styles={{ root: { marginBottom: 0 } }}
        />
        {limitMaximumAppointmentsPerDay ? (
          <>
            <Heading labelPaddings>limited to</Heading>
            <SpinNumberInputField
              name={bhbOnlineSettingsFormNameOf("maximumAppointmentsPerDay")}
              parse={value => Number(value)}
              styles={{ root: { width: 80 } }}
              min={1}
            />
          </>
        ) : (
          <Heading labelPaddings>unlimited</Heading>
        )}
      </Stack>
    </Stack>
  );
});
