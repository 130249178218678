import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { PresetButtonType, Stack, Text } from "@bps/fluent-ui";
import { BhbProviderDto } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { BhbOnlineApptType } from "@stores/bhb/models/BhbAppointmentType.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import { BhbProvidersOnlineFilter } from "./BhbProvidersCard.types.ts";

type BhbProvidersFilterProps = Pick<
  FilterBarProps<BhbProvidersOnlineFilter>,
  "children"
> & {
  providers: BhbProviderDto[];
  apptTypes: BhbOnlineApptType[];
};

export const BhbProvidersFilter: FunctionComponent<BhbProvidersFilterProps> =
  observer(({ children, apptTypes, providers }) => {
    const nameOf = nameOfFactory<BhbProvidersOnlineFilter>();
    const onlineAvailabilityOptions = [
      {
        key: "ShowAndBook",
        text: "Show & book"
      },
      {
        key: "ShowAndCall",
        text: "Show & call"
      },
      {
        key: "DontShow",
        text: "Don't show"
      }
    ];

    const providerOptions = providers.map(provider => {
      return {
        key: provider.id,
        text: provider.displayName
      };
    });

    const onlineAvailabilityPresetValues = ["ShowAndBook", "ShowAndCall"];

    const presets: PresetButtonType<BhbProvidersOnlineFilter>[] = [
      {
        name: nameOf("displayedOnlinePreset"),
        text: "Displayed online",
        iconName: "GlobeFavorite",
        tooltip: <Text>Show only displayed online appointment types</Text>,
        tooltipPresses: (
          <Stack horizontalAlign="center">
            <Text>Showing appointment types available online only.</Text>
            <Text>Release to show all</Text>
          </Stack>
        ),
        valuesToBeSetOnToggleOn: {
          onlineAvailability: onlineAvailabilityPresetValues
        },
        valuesToBeSetOnToggleOff: {
          onlineAvailability: undefined
        }
      }
    ];

    return (
      <FilterBar<BhbProvidersOnlineFilter>
        initialValues={{
          search: "",
          onlineAvailability: onlineAvailabilityPresetValues,
          displayedOnlinePreset: true
        }}
        items={[
          {
            name: nameOf("search"),
            type: "searchBox",
            stickItem: true,
            props: {
              id: "providers-filter-searchBox",
              placeholder: "Search",
              styles: { root: { width: 300 } },
              removeSpecialCharacters: false
            }
          },
          {
            type: "optionsSelect",
            name: nameOf("appointmentTypes"),
            props: {
              id: "providers-filter-appointmentTypes",
              placeholder: "Appointment type",
              styles: { root: { width: 155 } },
              options: apptTypes,
              multiSelect: true,
              hideSearchOption: false,
              calloutWidth: 300
            }
          },
          {
            type: "optionsSelect",
            name: nameOf("onlineAvailability"),
            props: {
              id: "providers-filter-onlineAvailability",
              placeholder: "Online availability",
              styles: { root: { width: 150 } },
              options: onlineAvailabilityOptions,
              multiSelect: true,
              hideSearchOption: true,
              calloutWidth: 200
            }
          },
          {
            type: "optionsSelect",
            name: nameOf("providers"),
            props: {
              id: "providers-filter-onlineproviders",
              placeholder: "Providers",
              styles: { root: { width: 100 } },
              options: providerOptions,
              multiSelect: true,
              hideSearchOption: false,
              calloutWidth: 300
            }
          }
        ]}
        presets={presets}
        styles={{ searchBox: { minWidth: 206 } }}
      >
        {children}
      </FilterBar>
    );
  });
